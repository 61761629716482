import React, { useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { useEffect } from "react";
import FilterModal from "../shared/filterModal";
import { api } from "../../utils/api";
import useSound from "use-sound";
import axios from "axios";

const NewMuslims = () => {
  const [users, setUsers] = useState([]);
  const [filtredData, setFiltredData] = useState([]);
  const [muslimsFile, setMuslimsFile] = useState(null);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isRequesting, setRequesting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [cancelToken, setCancelToken] = useState(null);

  // const keys =
  //   users &&
  //   users[0] &&
  //   Object.keys(users[0]).reduce((acc, key) => {
  //     if (
  //       key !== "password" &&
  //       key !== "_id" &&
  //       key !== "chahada" &&
  //       key !== "fb_link" &&
  //       key !== "__v" &&
  //       key !== "quizResult" &&
  //       key !== "credit" &&
  //       key !== "profile_photo" &&
  //       key !== "permissions" &&
  //       key !== "students" &&
  //       key !== "favourites" &&
  //       key !== "role" &&
  //       key !== "createdAt" &&
  //       key !== "updatedAt" &&
  //       key !== "accepted" &&
  //       key !== "haveTeacher" &&
  //       key !== "teacherId"
  //     ) {
  //       acc.push(key);
  //     }
  //     return acc;
  //   }, []);
  const titles = [
    "الاسم",
    "الدولة",
    "العمر",
    "الجنس",
    "الدين",
    "اللغة",
    "الداعية",
    "الفيسبوك",
    "المعلم",
    "التاريخ",
    "الشهادة",
  ];

  const transformDate = (date) => {
    const ndate = new Date(date);
    return ndate.toLocaleDateString("fr");
  };

  const chahada = (user) => {
    // eslint-disable-next-line
    const [play, { stop }] = useSound(user.chahada);
    return play;
  };

  const handleUpload = async () => {
    if (!muslimsFile) {
      alert("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("post_muslims_file", muslimsFile);
    setFileUploadLoading(true);
    try {
      const res = await api.post("/user/import-new-muslims", formData);
      if (res?.status == 200 || res?.status == 201) {
        getData();
      }
    } catch (error) {
      console.log(error);
    }
    setFileUploadLoading(false);
  };

  async function getData(page) {
    setLoading(false);
    setRequesting(true);
    // Create a new cancel token for the API request
    const source = axios.CancelToken.source();
    try {
      const response = await api.get("users", {
        params: {
          role: "NEW_MUSLIM",
          // haveTeacher: false,
          accepted: true,
          page,
        },
        cancelToken: source.token, // Assign the cancel token to the request
      });

      const { data, currentPage, totalPages, totalUsers } = response.data;
      setUsers(data);
      setFiltredData(data);
      setCurrentPage(currentPage);
      setTotalPages(totalPages);
      setTotalCount(totalUsers);
    } catch (error) {
      if (axios.isCancel(error)) {
        // Ignore canceled requests
        console.log("Request canceled", error.message);
      } else {
        console.log("Failed to fetch users: ", error);
      }
    } finally {
      setLoading(false);
      setRequesting(false);
    }
  }

  useEffect(() => {
    getData(currentPage);
    return () => {
      // Cancel the API request when the component unmounts
      if (cancelToken) {
        cancelToken.cancel("Request canceled");
      }
    };
  }, [currentPage]);

  // Pagination event handlers
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const usersPerPage = 10; // Number of users to display per page

  // Calculate the line number based on the current page and users per page
  const calculateLineNumber = (index) => {
    return (currentPage - 1) * usersPerPage + index + 1;
  };

  // Pagination event handlers
  const goToPreviousPage = () => {
    if (!isRequesting && currentPage > 1) {
      // Add check for isRequesting
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (!isRequesting && currentPage < totalPages) {
      // Add check for isRequesting
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <div className="students education content-grid">
        <div className="listStudent listDialogue">
          <div className="header-page">
            <Container>
              <div style={{ flexWrap: "wrap" }} className="content-header-page">
                <h2 className="title-page">New Muslims</h2>
                <div className="d-flex gap-4 align-items-center">
                  <Button variant="primary" onClick={() => setModalShow(true)}>
                    Filter
                  </Button>
                </div>
              </div>
            </Container>
          </div>

          <div className="d-flex gap-4 align-items-center m-3">
            <input
              type="file"
              onChange={(e) => setMuslimsFile(e.target.files[0])}
              accept=".xlsx"
            />
            <Button
              variant="primary"
              disabled={fileUploadLoading}
              onClick={handleUpload}
            >
              {fileUploadLoading ? "loading" : "Upload New Muslims"}
            </Button>
          </div>

          <div className="list-box">
            <Container>
              <Table striped bordered hover responsive>
                <thead>
                  <tr style={{ backgroundColor: "#e3b25f", color: "white" }}>
                    {titles.map((t) => (
                      <th
                        style={{
                          fontSize: "0.7rem",
                        }}
                        className="text-center  "
                      >
                        <p
                          className="text-truncate text-capitalize"
                          style={{
                            margin: "0 auto",
                          }}
                        >
                          {t}
                        </p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {isLoading
                    ? "loading"
                    : filtredData.map((user, index) => {
                        return (
                          <tr key={`muslim-${index}`}>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.name}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.country}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.age}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.gender}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.religion}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.language}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.referer}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                الفيسبوك:{" "}
                                <button
                                  style={{
                                    background: "#d2e8ff",
                                    height: 22,
                                    width: 80,
                                    borderRadius: 10,
                                    border: "none",
                                    fontSize: 14,
                                    color: "gray",
                                  }}
                                >
                                  <a
                                    href={user.fb_link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Visit
                                  </a>
                                </button>
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.haveTeacher}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {transformDate(user.date_creation)}
                              </p>
                            </td>
                            <td>
                              <button
                                onClick={() => chahada(user.chahada)}
                                style={{
                                  background: "#d2e8ff",
                                  height: 22,
                                  width: 80,
                                  borderRadius: 10,
                                  border: "none",
                                  fontSize: 14,
                                  color: "gray",
                                }}
                              >
                                Listen
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>
            </Container>
          </div>
          <div className="pagination m-3">
            <Button disabled={currentPage === 1} onClick={goToPreviousPage}>
              Previous
            </Button>
            <span>{`Page ${currentPage} of ${totalPages}`}</span>
            <Button
              disabled={currentPage === totalPages}
              onClick={goToNextPage}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      <FilterModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={users}
        setData={setFiltredData}
      />
    </>
  );
};

export default NewMuslims;
