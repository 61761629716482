import React, { useEffect, useState, useRef } from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";

import ReportItem from "./ReportItem";
import ImgProfile from "../../../assets/img/default_user.png";
import "./Report.scss";
import { api } from "../../../utils/api";
import * as _ from "lodash";
import { useParams } from "react-router-dom";
import { handlePermissions } from "../../../utils";
import FilterModal from "../../shared/filterModal";

const Report = () => {
  const [users, setusers] = useState([]);
  const [filtredData, setFiltredData] = useState([]);

  const { role } = useParams();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const deletedRef = useRef("");
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/users/all", {
          params: {
            accepted: true,
            role: role.toUpperCase(),
          },
        });
        setusers(data.data);
        setFiltredData(data.data);
      } catch (e) {
        console.log(e);
        handlePermissions(e);
      }
    })();
  }, [role]);

  function onDelete(id) {
    handleShow();
    deletedRef.current = id;
  }
  async function confirmDelete() {
    try {
      await api.delete("/user/" + deletedRef.current);
      let copy = _.cloneDeep(users);
      copy = copy.filter((user) => user.id !== deletedRef.current);
      setusers(copy);
      handleClose();
    } catch (e) {}
  }
  const reportItems = filtredData.map((user) => (
    <ReportItem
      key={user._id}
      onDelete={onDelete}
      id={user._id}
      img={user.profile_photo || ImgProfile}
      name={user.name}
      role={user.role}
    />
  ));
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div className="report">
        <div className="header-page header-report">
          <Container>
            <div className="content-header-page">
              <h2 className="title-page">All Users</h2>
              <Button variant="primary" onClick={() => setModalShow(true)}>
                Filter
              </Button>
            </div>
          </Container>
        </div>
        <div className="reportItem">
          <Container>
            <div className="content-report">
              <Row className="row-report">{reportItems}</Row>
            </div>
          </Container>
        </div>
      </div>
      <FilterModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={users}
        setData={setFiltredData}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove user confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are about to delete this user, Are you sure about that?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={confirmDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Report;
