import React from "react";
import { Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const ReportItem = (props) => {
  return (
    <Col sm={12} className="column-report col-md-6 col-lg-6" key={props.id}>
      <div className="box-user">
        <Link
          state={{
            name: props.name,
            img: props.img,
            id: props.id,
            role: props.role,
          }}
          to={`/report-result/` + props.id}
          className="details-user">
          <img
            className="img-profile"
            style={{ width: 100, height: 100 }}
            src={props.img}
            alt=""
          />
          <div className="info-user">
            <h4 className="name-user">{props.name}</h4>
            <p className="role-user">{props.role}</p>
          </div>
        </Link>
        <div className="action-report m-auto">
          <Button
            onClick={() => {
              props.onDelete(props.id);
            }}
            variant="default">
            <i className="fa fa-trash-o icon-button"></i>
          </Button>
        </div>
      </div>
    </Col>
  );
};

export default ReportItem;
