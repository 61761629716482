import React from "react";
import { Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ListLevelCourses from "./ListLevelCourses";
import { useQuery } from "@tanstack/react-query";
import { AxiosWithAuth } from "../../../context/axios";
const ListCoursesLang = () => {
  const { category } = useParams();
  const { isLoading, isError, data, error } = useQuery(["courses"], () =>
    AxiosWithAuth().get("courses/" + category)
  );
  if (isLoading) {
    return <span>Loading...</span>;
  }
  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className="list-box">
      <Container>
        <Row className="row-list-dialogue">
          {data.data.courses.map((course) => (
            <ListLevelCourses key={course._id} course={course} />
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ListCoursesLang;
