import { useState } from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { api } from "../../../utils/api";
import HttpHandlerHoc from "../../HOC/httpHandlerHoc";
import FilterItem from "./item";

const Filter = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/posts", {
          params: { populateuser: true, type: "filter" },
        });
        setPosts(data.posts);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  return (
    <>
      <div className="dialogue p-1">
        <div className="header-page header-dialogue">
          <Container>
            <div className="content-header-page">
              <h2 className="title-page">Filter</h2>
            </div>
          </Container>
        </div>
        {posts.map((post) => (
          <FilterItem data={post} key={post._id} />
        ))}
      </div>
    </>
  );
};

export default Filter;
