import React from "react";
import { Button, Container } from "react-bootstrap";
import { LANG } from "../../../utils";
import languageIcon from "../../../assets/img/home/language-icon.png";
import { Link } from "react-router-dom";

const SetLanguage = (props) => {
  const languageItems = Object.entries(LANG).map(([key, value]) => (
    <div className="language-item">
      <Container>
        <div className="content-language-item">
          <div className="level">
            <img src={languageIcon} alt="language" />
            <h4 className="name-lang">{value} level</h4>
          </div>
          <div className="action-lang">
            <Link to={`/add-quiz/${key}`} className="quiz">
              <i className="fa fa-plus-circle" />
              Quiz
            </Link>
            {/* <Button variant="dafault" className="delete">
              <i className="fa fa-trash" />
              Delete
            </Button> */}
          </div>
        </div>
      </Container>
    </div>
  ));

  return (
    <div className="languages-container border">
      <div className="header-page header-lang">
        <Container>
          <div className="content-header-page">
            <h2 className="title-page">Quiz Insert</h2>
          </div>
        </Container>
      </div>
      {languageItems}
    </div>
  );
};

export default SetLanguage;
