import React from "react";
import { Container, Row } from "react-bootstrap";
import { Outlet, useOutletContext } from "react-router-dom";

const CoursesCategoriesContainer = () => {
  const news = useOutletContext();
  return (
    <div className="courses education content-grid">
      <div className="listCourses listDialogue">
        <div className="list-box">
          <Container>
            <Row className="row-list-dialogue">
              <Outlet context={{ news }} />
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default CoursesCategoriesContainer;
