import React from "react";
import { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
const ModalAddCateg = ({
  show,
  handleClose,
  callback,
  heading,
  placeHolder,
}) => {
  const [title, setTitle] = useState("");

  const handleAdd = async () => {
    if (title) {
      callback(title);
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          size="lg"
          type="text"
          placeholder={placeHolder}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" className="btn-add" onClick={handleAdd}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddCateg;
