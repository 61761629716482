import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { api } from "../../../utils/api";

const ModalAddStudents = ({ show, handleClose, teacherId, language }) => {
  const [users, setUsers] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  console.log(language);
  useEffect(() => {
    (async () => {
      const { data } = await api.get("users/all", {
        params: {
          role: "NEW_MUSLIM",
          accepted: true,
          haveTeacher: false,
          language,
        },
      });
      setUsers(data.result.users);
    })();
  }, [language]);
  const handleAdd = async () => {
    try {
      await api.put(`user/teacher/add-student/${teacherId}`, {
        id: selectedId,
      });
      toast.success("Add student success");
      handleClose();
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Student to Teacher</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => setSelectedId(e.target.value)}
        >
          <option>Choice Student</option>
          <>
            {users.map((user) => (
              <option key={user._id + "option"} value={user._id}>
                {user.name}
              </option>
            ))}
          </>
        </Form.Select>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" className="btn-add" onClick={handleAdd}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddStudents;
