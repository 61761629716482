import { Button } from "react-bootstrap";

const CounterComp = ({
  handleAddClick,
  handleRemoveClick,
  counter = 1,
  notation = "",
}) => {
  return (
    <div className="box-counter">
      <Button variant="white" onClick={handleAddClick}>
        <i class="fa fa-chevron-up"></i>
      </Button>
      <p className="counter">
        {counter}
        {notation}
      </p>
      <Button variant="white" onClick={() => handleRemoveClick(counter)}>
        <i class="fa fa-chevron-down"></i>
      </Button>
    </div>
  );
};
export default CounterComp;
