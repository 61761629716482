import React from "react";

const StatsCard = (props) => {
  // console.log(props.number === "undefined" ? "false" : 'true');
  return (
    <div className={`card-container ${props.title}`}>
      <h2 className="title">{props.title}</h2>
      <h4 className="number">{props.number}</h4>
      <strong className="user">User</strong>
      <i className="fa fa-plus-circle" />
      <img src={props.image} alt={props.title} />
    </div>
  );
};

export default StatsCard;
