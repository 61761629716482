import { useContext } from "react";
import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setuser] = useState(JSON.parse(localStorage.getItem("user"))),
    [token, settoken] = useState(localStorage.getItem("token"));
  console.log(token);
  function updateUser(userU) {
    const user = JSON.stringify(userU);
    setuser(user);
    localStorage.setItem("user", user);
  }
  function updateAuth(token, user) {
    user && setuser(user);
    token && settoken(token);
    if (user == null) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    } else {
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
    }
  }
  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        updateAuth,
        updateUser,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
export default AuthContext;
