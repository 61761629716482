import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosWithAuth } from "../../../context/axios";
import Lesson from "./Lesson";
const AddCourse = () => {
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDesc] = useState("");
  const queryClient = useQueryClient();
  const { id, category } = useParams();
  console.log(id);
  const { data: lessons } = useQuery({
    queryKey: ["courses", id],
    queryFn: async () => {
      const { data } = await AxiosWithAuth().get(`courses/${category}/${id}`);
      return data.lessons;
    },
  });

  const { isLoading, mutate } = useMutation(
    (lesson) => {
      return AxiosWithAuth().post(`courses/${category}/${id}`, lesson);
    },
    {
      onSuccess: () => {
        setLink("");
        setTitle("");
        setDesc("");
        toast.success("lesson added succesfully");
        queryClient.invalidateQueries(["courses"]);
      },
      onError: () => {
        toast.error("problem creating your lesson");
      },
    }
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    mutate({
      title,
      video: link,
      description,
    });
  };
  return (
    <div className="list-box ">
      <Container>
        <Form className="form-quiz quizItem p-2" onSubmit={handleSubmit}>
          <Row className="align-items-center">
            <Col sm={12} md={10}>
              <Form.Control
                type="text"
                placeholder="Lesson Title"
                className="input-search"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                required={true}
                disabled={isLoading}
              />
              <Form.Control
                type="text"
                placeholder="Lesson video link"
                className="input-search my-4"
                onChange={(e) => setLink(e.target.value)}
                value={link}
                required={true}
                disabled={isLoading}
              />
              <Form.Control
                type="text"
                placeholder="Lesson description"
                className="input-search my-2"
                onChange={(e) => setDesc(e.target.value)}
                value={description}
                required={true}
                disabled={isLoading}
              />
            </Col>
            <Col>
              <Button variant="success" className="action-btn" type="submit">
                <i className="fa fa-check"></i>
              </Button>
            </Col>
          </Row>
        </Form>
        <div className="d-flex align-items-center justify-content-start">
          {lessons?.map((lesson) => (
            <Lesson lesson={lesson} key={lesson._id} />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default AddCourse;
