import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { AxiosWithAuth } from "../../../context/axios";

export default function AddComment() {
  const [comment, setComment] = useState("");
  const addComment = async () => {
    if (!comment) {
      toast.error("please add a comment");
    } else {
      const obj = {
        title: comment,
      };
      try {
        const res = await AxiosWithAuth().put("/channel/comments/add", obj);
        if (res.status == 200 || res.status == 201) {
          toast.success("added new comment to the Channel");
          setComment("");
        }
      } catch (error) {
        if (error.response.status == 400) {
          toast.error("please fill all inputs");
        } else {
          toast.error("something went wrong");
        }
      }
    }
  };
  return (
    <div className="file-input-container">
      <div className="input-label">Add Comment</div>
      <Form.Control
        type="text"
        placeholder="Add Comment"
        className="input-text input-title"
        name="comment"
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        required={true}
      />

      <Button
        type="button"
        className="btn btn-primary btn-add"
        onClick={addComment}
      >
        Add
      </Button>
    </div>
  );
}
