import React, { useState, useRef } from "react";
import { Container, Button, Form, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../utils/api";
import QuillEditor from "../../Editor";
import HttpHandlerHoc from "../../HOC/httpHandlerHoc";

const AddQuestionSuspicions = () => {
  const location = useLocation();
  const [title, settitle] = useState(
    location && location.state ? location.state.title : ""
  );
  const [body, setbody] = useState(
    location && location.state ? location.state.body : ""
  );
  const [titleError, settitleError] = useState("");
  const [bodyError, setbodyError] = useState("");
  const [photo, setphoto] = useState(
    location && location.state ? location.state.photo : ""
  );
  const [isloading, setisloading] = useState(false);
  const inputFile = useRef(null);
  const navigate = useNavigate();
  async function onSubmitQuestion() {
    settitleError(!title ? "Title field is required" : "");
    setbodyError(!title ? "Text field is required" : "");
    if (!title || !body) return;
    try {
      setisloading(true);
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      formData.append("type", "question");
      if (photo && typeof photo != "string")
        formData.append("post_photo", photo);
      if (location.state && location.state._id) {
        await api.put("posts/" + location.state._id, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else
        await api.post("posts", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      setisloading(false);
      navigate("/respone-suspicions");
    } catch (e) {
      console.log(e);
      setisloading(false);
    }
  }
  const [files, setFiles] = useState([]);

  console.log(photo);
  return (
    <>
      <div className="addQuestionSuspicions add-form-question">
        <Form className="form-question">
          <div className="header-page header-suspicions">
            <Container>
              <div className="content-header-page">
                <Row className="mb-3 row-input-quetion">
                  <Form.Group as={Col} controlId="formGridName">
                    <Form.Control
                      onChange={(event) => {
                        settitle(event.target.value);
                      }}
                      value={title}
                      type="text"
                      placeholder="Your Question"
                      className="input-question"
                    />
                  </Form.Group>
                </Row>
              </div>
            </Container>
          </div>
          <Container>
            <Row className="mb-3 row-input-quetion">
              <Col sm={12} md={9}>
                <QuillEditor
                  placeholder={"Start Posting Something"}
                  onEditorChange={(e) => setbody(e)}
                  onFilesChange={(files) => setFiles(files)}
                  defaultData={body}
                />
              </Col>
              <Form.Group
                controlId="formGridName"
                className="column-btn-img col-md-3 col-sm-12">
                <Button
                  variant="default"
                  className="btn-upload"
                  onClick={() => {
                    inputFile.current.click();
                  }}>
                  <i className="fa fa-picture-o icon-btn"></i>
                  {photo === "" ? "Add photo" : "update photo"}
                  <input
                    onChange={(event) => {
                      setphoto(event.target.files[0]);
                    }}
                    type="file"
                    id="file"
                    ref={inputFile}
                    accept=".png, .jpg, .jpeg"
                    style={{ display: "none" }}
                  />
                </Button>
                {photo ? (
                  <img
                    style={{ width: "100%" }}
                    src={
                      typeof photo == "string"
                        ? photo
                        : URL.createObjectURL(photo)
                    }
                    alt=""
                    className="img-dialogue"
                  />
                ) : null}
              </Form.Group>
            </Row>
            <Button
              variant="primary"
              className="btn-add"
              onClick={onSubmitQuestion}>
              {location && location.state ? "Update Question" : "Add Quetion"}
            </Button>
          </Container>
        </Form>
      </div>
    </>
  );
};

export default AddQuestionSuspicions;
