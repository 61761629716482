import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { AxiosWithAuth } from "../../../context/axios";
import Category from "./Category";

const CoursesCategories = () => {
  const { lang } = useParams();
  const { isLoading, isError, data, error } = useQuery(["categories"], () =>
    AxiosWithAuth().get("courses/categories", { params: { lang } })
  );
  if (isLoading) {
    return <span>Loading...</span>;
  }
  if (isError) {
    return <span>Error: {error.message}</span>;
  }
  console.log(data);
  return (
    <>
      {data?.data?.categories.map(({ _id, title, createdAt, count }) => (
        <Category
          key={_id}
          _id={_id}
          count={count}
          title={title}
          createdAt={createdAt}
        />
      ))}
    </>
  );
};

export default CoursesCategories;
