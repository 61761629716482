import { useState } from "react";
import { Form, InputGroup, Row } from "react-bootstrap";
import CounterComp from "./Counter";

export default function AddInputActivity({ value, setData, loading }) {
  const [counter, setCounter] = useState(value?.length || 1);
  const handleRemoveClick = () => {
    if (counter >= 2) {
      setCounter(() => counter - 1);
    }
  };

  const handleAddClick = () => {
    setCounter(() => counter + 1);
  };
  const handleChoices = (e) => {
    const newObj = { [e.target.name]: e.target.value };
    setData((current) => {
      const olddata = [...current];
      const i = current.findIndex((val) => val[e.target.name]);
      if (i === -1) {
        return [...current, newObj];
      } else {
        olddata[i] = newObj;
        return olddata;
      }
    });
  };
  return (
    <div className="m-q-container m-0 p-0">
      <CounterComp
        counter={counter}
        handleAddClick={handleAddClick}
        handleRemoveClick={handleRemoveClick}
      />
      <div className="w-100">
        {[...Array(counter).keys()].map((i, _i) => (
          <Row className="w-100 my-2" key={`m-q-choice_${_i}`}>
            <InputGroup className="m-q-input-g">
              <Form.Control
                style={{ margin: "0 2%" }}
                name={`choice_${_i}`}
                type="text"
                onChange={handleChoices}
                placeholder="Type a possible answer"
                defaultValue={
                  (value && value[_i] && value[_i][`choice_${_i}`]) || ""
                }
                required
                disabled={loading}
              />
              {/* <Form.Check aria-label="answer">
                <Form.Check.Input type="checkbox" isValid onChange={() => setCheck(true)}/>
                <Form.Check.Label>Right answer</Form.Check.Label>
                <Form.Control.Feedback type="valid">
                  Only check right answer!
                </Form.Control.Feedback>
              </Form.Check> */}
            </InputGroup>
          </Row>
        ))}
      </div>
    </div>
  );
}
