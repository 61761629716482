import { Button } from "react-bootstrap";

const FilterItem = ({ data }) => {
  return (
    <div className="dialogue-item-container p-2 w-100">
      <div className="info-dialogue px-4 py-1 pt-5">
        <h4 className="title-content">{data.title}</h4>
        <p className="text-truncate desc-dialogue">{data.body}</p>
      </div>
      <div className="action-dialogue">
        <Button variant="dafault" className="button-delete btn-action">
          <i className="fa fa-trash icon-btn-delete icon-btn" />
          Delete
        </Button>
      </div>
    </div>
  );
};

export default FilterItem;
