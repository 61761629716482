import React, { useContext } from "react";

import { Container, Button } from "react-bootstrap";

import TabProfile from "./tabProfile";
import ListDialogue from "./ListDialogue";
import ImgProfile from "../../../assets/img/default_user.png";

import "./Profile.scss";
import HttpHandlerHoc from "../../HOC/httpHandlerHoc";
import { useEffect } from "react";
import { api } from "../../../utils/api";
import { useState } from "react";
import { useAuth } from "../../HOC/AuthContext";
import { handlePermissions } from "../../../utils";
import { Link } from "react-router-dom";

const Profile = (props) => {
  const { user } = useAuth();
  const [questions, setquestions] = useState([]);
  const [dialogues, setdialogues] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/posts");
        setquestions(data.posts.filter((elt) => elt.type == "question"));
        setdialogues(data.posts.filter((elt) => elt.type == "dialogue"));
      } catch (e) {
        handlePermissions(e);
      }
    })();
  }, []);
  return (
    <>
      <div className="profile">
        <Container>
          <h1 className="title-page">My Profile</h1>
        </Container>
        <div className="header-page header-lang">
          <Container>
            <div className="content-header-page">
              <div className="content-profile">
                <div className="upload-img-profile">
                  <img
                    src={user.profile_photo || ImgProfile}
                    alt=""
                    className="img-profile"
                  />
                </div>
                <div className="info-user">
                  <h4 className="name-user">{user.name}</h4>
                  <p className="role-user">{user.role}</p>
                  <Link to={`/edit-profile`} className="btn-profile">
                    Edit Profile
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <TabProfile questions={questions} />
        <ListDialogue dialogues={dialogues} />
      </div>
    </>
  );
};

export default Profile;
