import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { api } from "../../../utils/api";
import HttpHandlerHoc from "../../HOC/httpHandlerHoc";

const Answer = () => {
  const { id } = useParams();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [body, setBody] = useState("");
  console.log(answer);
  console.log(question);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/inquiry/" + id);
        setQuestion(data.question.body);
        if (data.answer) {
          setAnswer(data.answer.body);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [setQuestion, id]);

  const handleAnswer = async () => {
    try {
      const { data } = await api.patch("/inquiry/" + id, { body });
      setAnswer(data.inquiry.answer.body);
      setBody("");
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <div className="suspicions-container">
        <div className="header-page header-suspicions">
          <Container>
            <div className="content-header-page">
              <h2 className="title-page">Answer Inquiries</h2>
            </div>
          </Container>
        </div>
        <div className="messagesContainer">
          <div className="messages">
            <p className="message">{question}</p>
            {answer ? (
              <p className="message" style={{ marginLeft: "auto" }}>
                {answer}
              </p>
            ) : null}
          </div>
          <div className="inputContainer">
            <input
              type="text"
              placeholder="some answer ..."
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
            <button onClick={handleAnswer}>Send</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Answer;
