import React from "react";
import { Button, Col } from "react-bootstrap";
import { api } from "../../../utils/api";

const ReportItem = (props) => {
  const handleAccept = async () => {
    await api.put(`user/accept-new-muslim/${props.id}`);
    props.setChange((v) => !v);
  };
  return (
    <Col className="column-report my-2" xs={12} md={6}>
      <div className="box-user my-2 w-100">
        <div className="details-user">
          <img
            className="img-profile"
            style={{ width: 80, height: 80 }}
            src={props.img}
            alt={props.name}
          />
          <div className="info-user">
            <h4 className="name-user">{props.name}</h4>
            <p className="role-user">{props.role}</p>
          </div>
        </div>
        <div className="action-report">
          <Button onClick={() => handleAccept()} variant="primary">
            accept
          </Button>
        </div>
      </div>
    </Col>
  );
};

export default ReportItem;
