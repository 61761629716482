import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosWithAuth } from "../../../context/axios";
import { youtubeLinkToEmbed } from "../../../utils";

const Lesson = ({ lesson }) => {
  console.log(lesson);
  const queryClient = useQueryClient();
  const { category, id } = useParams();

  const { isLoading, mutate } = useMutation(
    () => {
      return AxiosWithAuth().delete(`courses/${category}/${id}/${lesson._id}`);
    },
    {
      onSuccess: () => {
        toast.success("lesson deleted succesfully");
        queryClient.invalidateQueries(["courses"]);
      },
      onError: () => {
        toast.error("problem deleting lesson");
      },
    }
  );
  return (
    <Row
      className="p-2 ms-4 rounded mb-5 flex-column"
      style={{
        backgroundColor: "#D2E8FF",
        flexBasis: "30%",
        flexWrap: "nowrap",
        height: 300,
        overflow: "hidden",
        direction: lesson.language === "arabic" ? "ltr" : "rtl",
      }}>
      <Col sm={"auto"}>
        <iframe
          className="media-dialogue"
          width="100%"
          height="100%"
          src={youtubeLinkToEmbed(lesson.video)}
          title={lesson.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
      </Col>
      <Col sm={"auto"} className="mt-2" style={{ flexGrow: 1 }}>
        <Row>
          <h4 className="text-truncate col-10">{lesson.title}</h4>
        </Row>
        <Row>
          <p className="text-muted text-truncate col-10">
            {lesson.description}
          </p>
        </Row>
      </Col>
      <Col sm={"auto"} className="align-self-center">
        <Button variant="danger ms-2" onClick={mutate}>
          Delete
        </Button>
        <Button variant="primary">Watch</Button>
      </Col>
    </Row>
  );
};

export default Lesson;
