import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";
import ModalAddCateg from "./ModalAddCateg";

import languageIcon from "../../../assets/img/home/language-icon.png";
import { Link, useNavigate } from "react-router-dom";
import { textToSlug } from "../../../utils";
import { AxiosWithAuth } from "../../../context/axios";
import { toast } from "react-toastify";

const LanguageItem = (props) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false),
    handleShow = () => {
      setShow(true);
    };
  const handleAdd = async (title) => {
    try {
      const { data } = await AxiosWithAuth().post(`/courses`, {
        title,
        lang: props.lang,
      });

      navigate(`${props.lang}/${data.courseCategory._id}`);
    } catch (error) {
      console.log(error);
      toast.error("problem creating your category");
    }

    handleClose();
  };
  return (
    <div className="language-item">
      <Container>
        <div className="content-language-item">
          <div className="level">
            <img src={languageIcon} alt="language" />
            <h4 className="name-lang">{props.label} level</h4>
          </div>
          <div className="action-lang">
            <Button
              variant="default"
              style={{ padding: "0 20px 0 0" }}
              onClick={handleShow}
              className="quiz">
              <i className="fa fa-plus-circle" />
              Add Categ
            </Button>
            <Link
              to={props.lang}
              style={{ marginRight: "5px" }}
              className="delete">
              <i className="fa fa-eye" style={{ margin: "0 16px 0 8px" }} />
              View
            </Link>
          </div>
        </div>
      </Container>
      <ModalAddCateg
        handleClose={handleClose}
        show={show}
        callback={handleAdd}
        heading={"Add category"}
        placeHolder={"type your category title"}
      />
    </div>
  );
};

export default LanguageItem;
