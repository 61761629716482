import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../utils/api";
import HttpHandlerHoc from "../../HOC/httpHandlerHoc";
import * as _ from "lodash";
import SuspicionItem from "./SuspicionItem";

import "./Suspicions.scss";

const Suspicions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [questions, setquestions] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const deleteId = useRef("");
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/posts");
        setquestions(data.posts.filter((elt) => elt.type === "question"));
      } catch (e) {}
    })();
  }, []);

  async function confirmDelete() {
    try {
      await api.delete("/posts/" + deleteId.current);
      handleClose();
      let qs = _.cloneDeep(questions);
      qs = qs.filter((elt) => elt._id !== deleteId.current);
      setquestions(qs);
    } catch (e) {
      console.log(e);
    }
  }
  function onDelete(id) {
    deleteId.current = id;
    handleShow();
  }

  const suspicionItems = questions.map((question) => (
    <SuspicionItem id={question._id} onDelete={onDelete} question={question} />
  ));
  return (
    <>
      <div className="suspicions-container">
        <div className="header-page header-suspicions">
          <Container>
            <div className="content-header-page">
              <h2 className="title-page">Add Question</h2>
              <Link to="/addquestion-suspicions" className="btn-add">
                <i className="fa fa-plus"></i>
              </Link>
            </div>
          </Container>
        </div>
        {suspicionItems}
      </div>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove user confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are about to delete this post, Are you sure about that?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={confirmDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Suspicions;
