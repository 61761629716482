import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import * as _ from "lodash";
import "./Dialogue.scss";
import { Link } from "react-router-dom";
import { api } from "../../../utils/api";
import HttpHandlerHoc from "../../HOC/httpHandlerHoc";
import DialogueCard from "./dialogueCard";
import DialogueItem from "./DialogueItem";

const Dialogue = () => {
  const [dialogues, setdialogues] = useState([]);
  const deleteId = useRef("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/posts");
        setdialogues(data.posts.filter((elt) => elt.type === "dialogue"));
      } catch (e) {}
    })();
  }, []);
  async function confirmDelete() {
    try {
      await api.delete("/posts/" + deleteId.current);
      handleClose();
      let qs = _.cloneDeep(dialogues);
      qs = qs.filter((elt) => elt._id != deleteId.current);
      setdialogues(qs);
    } catch (e) {
      console.log(e);
    }
  }
  function onDelete(id) {
    deleteId.current = id;
    handleShow();
  }
  const DialogueItems = dialogues.map((elt) => (
    <DialogueItem onDelete={onDelete} dialogue={elt} key={elt._id} />
  ));

  return (
    <>
      <div className="dialogue p-1">
        <div className="header-page header-dialogue">
          <Container>
            <div className="content-header-page">
              <h2 className="title-page">Dialogue methodes</h2>
              <Link to="/add-dialogue" className="btn-add">
                <i className="fa fa-plus"></i>
              </Link>
            </div>
          </Container>
        </div>
        <Container>{DialogueItems}</Container>
      </div>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove user confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are about to delete this post, Are you sure about that?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={confirmDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Dialogue;
