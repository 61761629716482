import React from 'react';
import { Outlet } from 'react-router-dom';

import SideBar from './sidebar';

const Layout = props => {
  return (
    <div className="appglobal">
      <SideBar />
      <div className='app-content'>
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;
