import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import bars from "../../../assets/img/bars.png";
import logo from "../../../assets/img/home/logos.png";

import routes from "../../../constants/sidebaritems";
import ButtonAdd from "./ButtonAdd";

import "./SideBar.scss";
import AppContext from "../../../context/appContext";
import { useAuth } from "../../HOC/AuthContext";

const SideBar = (props) => {
  const [dropSidebar, setDropSidebar] = useState(false);
  const location = useLocation();
  const context = useContext(AppContext);
  const { user } = useAuth();
  const onLogout = () => {
    try {
      context.deleteInfos();
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.reload();
    } catch (e) {}
  };

  const changeNavbarColor = () => {
    if (window.innerWidth <= 991) {
      setDropSidebar(true);
    } else {
      setDropSidebar(false);
    }
  };

  useEffect(() => {
    changeNavbarColor();
  }, []);
  return (
    <div className={`sidebar ${dropSidebar ? "closeSidebar" : ""}`}>
      <div className="topSidbar">
        <Container>
          <Row className="align-items-center">
            <Col>
              <div className="content-logo">
                <Button
                  variant="default"
                  onClick={() => setDropSidebar(!dropSidebar)}>
                  <img src={logo} alt="منصة بلاغ" className="logo" />
                </Button>
                {/* <h2 className="title-logo">منصة بلاغ</h2> */}
              </div>
            </Col>
            <Col className="text-end">
              <Button
                variant="default"
                className="btn-dropBar"
                onClick={() => setDropSidebar(!dropSidebar)}>
                <img src={bars} alt="منصة-بلاغ" />
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <ul className="list-links">
        {routes &&
          routes.map((link, index) => (
            <li
              className={`${location.pathname === link.path ? "active" : ""}`}
              key={index}>
              <Link to={link.path} className="link-sidebar">
                <div className="img-icon-list">
                  <img className="icon-link" src={link.src} alt="" />
                </div>
                <span className="title-link">{link.title}</span>
              </Link>
            </li>
          ))}
        <li>
          <Button onClick={onLogout} className="link-sidebar" variant="default">
            <div className="img-icon-list">
              <i className="fa fa-sign-out icon-logout"></i>
            </div>
            <span className="title-link">Logout</span>
          </Button>
        </li>
      </ul>
      {user &&
      user.permissions &&
      user.permissions.indexOf("create_sub_account") !== -1 ? (
        <ButtonAdd />
      ) : null}
    </div>
  );
};

export default SideBar;
