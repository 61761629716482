import React, { useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import QuillEditor from "../../Editor";
import { api } from "../../../utils/api";
import { AxiosWithAuth } from "../../../context/axios";
import { useLocation, useNavigate } from "react-router-dom";
const AddDialogue = () => {
  const location = useLocation();
  const [files, setFiles] = useState([]);
  const [title, settitle] = useState(
    location && location.state ? location.state.title : ""
  );
  const [body, setbody] = useState(
    location && location.state ? location.state.body : ""
  );
  const [titleError, settitleError] = useState("");
  const [bodyError, setbodyError] = useState("");
  const [photo, setphoto] = useState(
    location && location.state ? location.state.photo : ""
  );
  const [video, setvideo] = useState(
    location && location.state ? location.state.video : ""
  );
  const [isloading, setisloading] = useState(false);
  const inputFile = useRef(null);
  const inputFile1 = useRef(null);
  const navigate = useNavigate();

  async function onSubmitQuestion() {
    settitleError(!title ? "Title field is required" : "");
    setbodyError(!title ? "Text field is required" : "");
    if (!title || !body) return;
    try {
      setisloading(true);
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      formData.append("type", "dialogue");
      if (photo && typeof photo != "string")
        formData.append("post_photo", photo);
      if (video && typeof video != "string")
        formData.append("post_video", video);
      if (location.state && location.state._id) {
        await AxiosWithAuth().put("posts/" + location.state._id, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else
        await AxiosWithAuth().post("posts", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      setisloading(false);
      navigate("/dialogue");
    } catch (e) {
      console.log(e);
      setisloading(false);
    }
  }
  return (
    <div className="addDialogue add-form-question">
      <Form className="form-question">
        <div className="header-page header-suspicions">
          <Container>
            <div className="content-header-page">
              <Row className="mb-3 row-input-quetion">
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Control
                    onChange={(event) => {
                      settitle(event.target.value);
                    }}
                    value={title}
                    type="text"
                    placeholder="Add Title"
                    className="input-question"
                  />
                </Form.Group>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row className="mb-3 row-input-quetion align-items-start">
            <Col sm={12} md={9}>
              <QuillEditor
                placeholder={"Start Posting Something"}
                onEditorChange={(e) => setbody(e)}
                onFilesChange={(files) => setFiles(files)}
                defaultData={body}
              />
            </Col>
            <Form.Group
              controlId="formGridName"
              className="column-btn-img col-md-3 col-sm-12">
              <Button
                onClick={() => {
                  inputFile.current.click();
                }}
                variant="default"
                className="btn-upload">
                <i className="fa fa-picture-o icon-btn"></i>
                {photo === "" ? "Add photo" : "update photo"}
              </Button>
              <Button
                onClick={() => {
                  inputFile1.current.click();
                }}
                variant="default"
                className="btn-upload">
                <i className="fa fa-video-camera icon-btn"></i>
                {video === "" ? "Add video" : "update video"}
              </Button>
              <input
                onChange={(event) => {
                  setphoto(event.target.files[0]);
                }}
                type="file"
                id="file"
                ref={inputFile}
                accept=".png, .jpg, .jpeg"
                style={{ display: "none" }}
              />
              <input
                onChange={(event) => {
                  setvideo(event.target.files[0]);
                }}
                type="file"
                id="file"
                accept="video/mp4,video/x-m4v,video/*"
                ref={inputFile1}
                style={{ display: "none" }}
              />
            </Form.Group>
          </Row>
          <Row style={{ justifyContent: "center", alignItems: "center" }}>
            {photo ? (
              <img
                style={{ width: 700, height: 400 }}
                src={
                  typeof photo == "string" ? photo : URL.createObjectURL(photo)
                }
                alt=""
                className="img-dialogue"
              />
            ) : null}
            {video ? (
              <video
                style={{ marginTop: 10 }}
                width="320"
                height="240"
                controls>
                <source
                  src={
                    typeof video == "string"
                      ? video
                      : URL.createObjectURL(video)
                  }
                  type="video/mp4"
                />
              </video>
            ) : null}
          </Row>

          <Button
            onClick={onSubmitQuestion}
            variant="primary"
            className="btn-add">
            {location && location.state ? "Update Dialogue" : "Add Dialogue"}
          </Button>
        </Container>
      </Form>
    </div>
  );
};

export default AddDialogue;
