import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { api } from "../../../utils/api";
import HttpHandlerHoc from "../../HOC/httpHandlerHoc";
import Question from "./q";

import "./Suspicions.scss";

const Inquiry = () => {
  const [questions, setquestions] = useState([]);
  const [filter, setFilter] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/inquiry", {
          params: {
            answered: filter,
          },
        });
        setquestions(data.inquiries);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [filter]);

  return (
    <>
      <div className="suspicions-container">
        <div className="header-page header-suspicions">
          <Container>
            <div className="content-header-page">
              <h2 className="title-page">Answer Inquiries</h2>
              <select
                name="type"
                className="py-2 px-4"
                style={{ borderLeft: "4px solid #00376f", color: "#00376f" }}
                onChange={(e) => setFilter(e.target.value)}>
                <option>filter</option>
                <option value={false}>New</option>
                <option value={true}>Answered</option>
              </select>
            </div>
          </Container>
        </div>
        <Row className="d-flex">
          {questions.map(({ _id, question, answer, answered }) => (
            <Question
              _id={_id}
              answer={answer}
              answered={answered}
              question={question}
            />
          ))}
        </Row>
      </div>
    </>
  );
};

export default Inquiry;
