import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { AxiosWithAuth } from "../../../context/axios";
import AddInputActivity from "./AddInputActivity";
import "./QuizItem.scss";
const OldQuizItem = ({ quiz }) => {
  const { question, _id, answer, quizType: OldQuizType } = quiz;
  const [q, setQ] = useState(question);
  const [quizType, setQuizType] = useState(OldQuizType);
  const [data, setData] = useState(answer);
  const [update, setUpdate] = useState(false);
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(
    () => {
      return AxiosWithAuth().delete(`quizes/${_id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["quizes"]);
        toast.success("deleted succesfully!");
      },
      onError: () => {
        toast.error("delete unsuccesful");
      },
    }
  );
  const updateMutation = useMutation(
    (quiz) => {
      return AxiosWithAuth().put(`quizes/${_id}`, quiz);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["quizes"]);
        toast.success("updated succesfully!");
        setUpdate(false);
      },
      onError: () => {
        toast.error("update unsuccesful");
      },
    }
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    updateMutation.mutate({
      quizType,
      question: q,
      answer: data,
    });
  };
  const handleDelete = async () => {
    deleteMutation.mutate();
  };
  const handleReset = () => {
    setQ(question);
    setData(answer);
    setQuizType(OldQuizType);
    setUpdate(false);
  };
  return (
    <Form
      className="form-quiz quizItem mb-5 rounded p-2 mb-5"
      onSubmit={handleSubmit}
      style={{ backgroundColor: "#b8c8d9" }}>
      <Row className="justify-content-between">
        <Col sm="11">
          <Row className="row-answer search mb-3 justify-content-between">
            <Form.Control
              type="text"
              name="question 1"
              placeholder="Question"
              className="input-search"
              onChange={(e) => setQ(e.target.value)}
              value={q}
              required={true}
              disabled={!update}
            />
          </Row>
          <Row className="column-list-answer justify-content-between my-3">
            <Col
              controlId="formRoel"
              className="colun-role-quiz"
              xs={"auto"}
              md="4">
              <Button
                variant={quizType === "one" ? "primary" : "default"}
                className="btn-role-quiz"
                disabled={!update}
                onClick={() => setQuizType("one")}>
                <i className="fa fa-circle-o icon-circle"></i>
                One answer
              </Button>
            </Col>
            <Col
              controlId="formRoel"
              className="colun-role-quiz"
              xs={"auto"}
              md="4">
              <Button
                variant={quizType === "more" ? "primary" : "default"}
                className="btn-role-quiz"
                disabled={!update}
                onClick={() => setQuizType("more")}>
                <i className="fa fa-circle icon-circle"></i>
                Multiple choice
              </Button>
            </Col>
          </Row>
          <Row className="column-list-answer justify-content-between mb-2">
            {quizType === "one" ? (
              <Form.Control
                id="answer"
                name="answer"
                type="text"
                placeholder="Answer"
                className="input-answer"
                onChange={(e) => setData([{ [e.target.name]: e.target.value }])}
                value={data[0]?.answer || ""}
                required={true}
                disabled={!update}
              />
            ) : (
              <AddInputActivity
                setData={setData}
                value={data}
                loading={
                  (updateMutation.isLoading || deleteMutation.isLoading) ^
                  !update
                }
                update={update}
              />
            )}
          </Row>
        </Col>
        <Col sm="1" className="buttons">
          <Button
            style={{ display: !update ? "block" : "none" }}
            variant="primary"
            className="action-btn"
            type="button"
            onClick={() => setUpdate(true)}>
            <i class="fa fa-pencil"></i>
          </Button>
          <Button
            style={{ display: update ? "block" : "none" }}
            variant="success"
            className="action-btn"
            onClick={handleReset}
            disabled={updateMutation.isLoading || deleteMutation.isLoading}>
            <i class="fa fa-refresh"></i>
          </Button>
          <Button
            style={{ display: update ? "block" : "none" }}
            variant="success"
            className="action-btn my-2"
            type="submit"
            disabled={updateMutation.isLoading || deleteMutation.isLoading}>
            <i class="fa fa-check"></i>
          </Button>

          <Button
            variant="danger"
            className="action-btn"
            disabled={updateMutation.isLoading || deleteMutation.isLoading}
            onClick={handleDelete}>
            <i className="fa fa-trash"></i>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default OldQuizItem;
