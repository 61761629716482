import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "./components/auth/login";
import Layout from "./components/dashboard/Layout";
import Home from "./components/dashboard/home";
import Profile from "./components/dashboard/profile";
import Suspicions from "./components/dashboard/suspicions";
import AddQuestionSuspicions from "./components/dashboard/suspicions/AddQuestionSuspicions";
import Dialogue from "./components/dashboard/dialogue";
import AddDialogue from "./components/dashboard/dialogue/AddDialogue";
import Language from "./components/dashboard/setLanguage";
import ReportsUser from "./components/dashboard/Report/ReportsUser";
import TasksUser from "./components/dashboard/Report/TasksUser";
import Teachers from "./components/dashboard/Teachers";
import NewMuslim from "./components/dashboard/NewMuslim";
import Quiz from "./components/dashboard/Quiz";
import ListCoursesLang from "./components/dashboard/setLanguage/ListCoursesLang";

import "./App.scss";
import Quizes from "./components/dashboard/Quiz/Quizes";
import RequireAuth from "./components/HOC/RequireAuth";
import DialogueDetail from "./components/dashboard/dialogue/DialogueDetail";
import RequestedUsers from "./components/dashboard/RequestedUsers";
import AddCourse from "./components/dashboard/setLanguage/AddCourse";
import CoursesCategories from "./components/dashboard/setLanguage/CoursesCategories";
import Courses from "./components/dashboard/setLanguage/Courses";
import CoursesCategoriesContainer from "./components/dashboard/setLanguage/CourseCategoriesContainer";
import Filter from "./components/dashboard/filter";
import Report from "./components/dashboard/Report";
import Inquiry from "./components/dashboard/inquiry";
import Answer from "./components/dashboard/inquiry/answer";
import MonthlyTasks from "./components/dashboard/Report/MonthlyTasks";
import NewMuslims from "./components/dashboard/NewMuslims";
import Channel from "./components/dashboard/channel";
import EditChannel from "./components/dashboard/editChannel";
import EditProfile from "./components/dashboard/profile/EditProfile";

function App() {
  return (
    <Routes>
      <Route index path="/login" element={<Login />} />
      <Route element={<RequireAuth allowedRoles={["ADMIN"]} />}>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Home />} />
          <Route path="profile" element={<Profile />} />
          <Route path="edit-profile" element={<EditProfile />} />
          <Route path="channel" element={<Channel />} />
          <Route path="edit-channel" element={<EditChannel />} />
          <Route path="dialogue" exact element={<Dialogue />} />
          <Route path="dialogue/:id" element={<DialogueDetail />} />
          <Route path="add-dialogue" element={<AddDialogue />} />
          <Route path="filter" element={<Filter />} />
          <Route path="courses" element={<Courses />}>
            <Route index element={<Language />} />
            <Route path=":lang" element={<CoursesCategoriesContainer />}>
              <Route index element={<CoursesCategories />} />
              <Route path=":category" element={<ListCoursesLang />} />
              <Route path=":category/:id" element={<AddCourse />} />
            </Route>
          </Route>
          <Route path="quiz" element={<Quiz />} />
          <Route path="add-quiz/:lang" element={<Quizes />} />
          <Route path="respone-suspicions" element={<Suspicions />} />
          <Route path="inquiry" element={<Inquiry />} />
          <Route path="inquiry/:id" element={<Answer />} />
          <Route
            path="addquestion-suspicions"
            element={<AddQuestionSuspicions />}
          />
          <Route path="requested-users" element={<RequestedUsers />} />
          <Route path="report/:role" element={<Report />} />
          <Route path="report-result/:user" element={<ReportsUser />} />
          <Route path="report-result/:user/:task" element={<TasksUser />} />
          <Route
            path="report-result/:user/:year/:month"
            element={<MonthlyTasks />}
          />

          <Route path="qualify" element={<Home />} />
          <Route path="invitation" element={<NewMuslim />} />
          <Route path="new-muslims" element={<NewMuslims />} />
          <Route path="education" element={<Teachers />} />
        </Route>
      </Route>

      <Route path="*" element={<Login />} />
    </Routes>
  );
}

export default App;
