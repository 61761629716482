import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImgDialogue from "../../../assets/img/dialogue.png";

const ListDialogue = (props) => {
  return (
    <div className="listDialogueProfile">
      <div className="header-content-profile">
        <Container>
          <div className="header-profile-body">
            <Link to="/add-dialogue" className="btn-add">
              <i className="fa fa-plus"></i>
            </Link>
            <h4 className="title-header-content">Dialogue methodes</h4>
          </div>
        </Container>
      </div>
      <div className="items-questions">
        {props.dialogues.map((item, index) => (
          <Container className="d-flex align-items-center">
            <img src={ImgDialogue} alt="" style={{ width: 160, height: 130 }} />
            <h4 className="w-100 ms-2">{item.title}</h4>
            <div className="action-lang action-dialogue">
              <Link
                to={{
                  pathname: "/add-dialogue",
                  state: item,
                }}
                className="link-action">
                <i className="fa fa-angle-right" />
              </Link>
            </div>
          </Container>
        ))}
      </div>
    </div>
  );
};

export default ListDialogue;
