import React, { useState } from "react";
import { Card, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImgProfile from "../../../assets/img/profile.png";
import ModalAddStudents from "./ModalAddStudents";
import en from "react-phone-number-input/locale/en.json";
import { LANG } from "../../../utils";
import { api } from "../../../utils/api";
import { toast } from "react-toastify";
const ItemEducation = ({ user, handleChanges }) => {
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  const handleClose = () => setShow(false),
    handleShow = () => {
      setShow(true);
    };
  const handleDelete = async () => {
    try {
      await api.delete(`user/${user._id}`);
      toast.success("Delete user success");
      handleChanges((v) => !v);
      setModalShow(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <div className="column-dialogue col-lg-3 col-md-4 col-sm-6 col-xs-12">
      <Card>
        <Link to="/teachers/student/1">
          <Card.Img
            className="media-dialogue"
            variant="top"
            src={user.profile_photo || ImgProfile}
          />
        </Link>
        <Card.Body>
          <div className="content-body">
            <Link to="/daaiya/dialogue/1">
              <Card.Title>{user.name}</Card.Title>
              <p className="info-country">{en[user.country]}</p>
              <p className="info-age">
                <strong>{LANG[user.language]}</strong>
              </p>
            </Link>
          </div>
        </Card.Body>
        <Card.Footer>
          <Button variant="default" onClick={() => setModalShow(true)}>
            <i className="fa fa-trash"></i>
          </Button>
          <Button variant="default" onClick={handleShow}>
            <i className="fa fa-plus"></i>
          </Button>
        </Card.Footer>
      </Card>
      <Modal centered show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Remove user confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are about to delete this teacher, Are you sure about that?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
          <Button variant="success" onClick={handleDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalAddStudents
        handleClose={handleClose}
        show={show}
        teacherId={user._id}
        language={user.language}
      />
    </div>
  );
};

export default ItemEducation;
