import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { handlePermissions } from "../../../utils";
import { api } from "../../../utils/api";

export default function EditProfile() {
  const params = useParams();
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    new_password: "",
  });

  const handleInputChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  async function updateCurrentUser() {
    if (!data.name) {
      toast.error("Name cannot be empty");
      return;
    }

    if (!data.email) {
      toast.error("Email cannot be empty");
      return;
    }

    const res = await api.post(`/user/update`, data);
    if (res.status == 200 || res.status == 201) {
      toast.success("successfuly updated");
    } else {
      toast.error(res.response.data.message);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/user`);
        setData((prev) => ({
          ...prev,
          name: data.user.name,
          email: data.user.email,
        }));
      } catch (e) {
        handlePermissions(e);
      }
    })();
  }, []);
  return (
    <div className="profile">
      <Container>
        <h1 className="title-page">My Profile</h1>
      </Container>
      <Container>
        <div className="header-page header-lang">
          <Form.Label>Name:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Add Name"
            className="input-text input-title mb-4"
            name="name"
            onChange={handleInputChange}
            value={data.name}
            required={true}
          />

          <Form.Label>Email:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Add Email"
            className="input-text input-title mb-4"
            name="email"
            onChange={handleInputChange}
            value={data.email}
            required={true}
          />

          <Form.Label>Old Password:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your old password"
            className="input-text input-title mb-4"
            name="password"
            onChange={handleInputChange}
            value={data.password}
            required={true}
          />

          <Form.Label>New Password:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your New password"
            className="input-text input-title mb-4"
            name="new_password"
            onChange={handleInputChange}
            value={data.new_password}
            required={true}
          />

          <Button
            type="button"
            className="btn btn-primary btn-add"
            onClick={updateCurrentUser}
          >
            Update
          </Button>
        </div>
      </Container>
    </div>
  );
}
