import React from 'react';

import './Input.scss'


const Input = ({
  id,
  onChange,
  placeholder,
  className,
  iconImage,
  imageClass,
  icon,
  type,
  rest
}) => {
  return (
    <div className={`${className} custom-input`}>
      <div className="custom-icon">
        {icon && <i className={icon} />}
        {iconImage ? (
          <img className={imageClass} src={iconImage} alt="custom-icon" />
        ):null}
      </div>
      <input
        id={id}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
}

export default Input;
