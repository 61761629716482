import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthContext";
const RequireAuth = ({ allowedRoles, redirectPath = "/login" }) => {
  const { user } = useAuth();
  const location = useLocation();
  return user && allowedRoles?.includes(user?.role) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
