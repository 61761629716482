import React, { useState, useEffect } from "react";

import StatsCard from "./StatsCard";
import Advocacy from "../../../assets/img/home/Advocacy.png";
import Identifier from "../../../assets/img/home/Identifier.png";
import nonMuslims from "../../../assets/img/home/non-Muslims.png";
import ImgCoup from "../../../assets/img/home/coup.png";
import ImgStars from "../../../assets/img/home/icons.png";

import "./Home.scss";
import { Container } from "react-bootstrap";
import { api } from "../../../utils/api";

const Home = (props) => {
  const [stats, setstats] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/users/statistics");
        const newstats = {
          total: 0,
          countTranslator: 0,
          countIdentifier: 0,
          countAdvocate: 0,
          countNewMuslim: 0,
        };
        data.result.forEach((elt) => {
          if (elt._id !== "ADMIN") newstats.total += elt.count;
          if (elt._id === "ADVOCATE") newstats.countAdvocate = elt.count;
          if (elt._id === "TRANSLATOR") newstats.countTranslator = elt.count;
          if (elt._id === "IDENTIFIER") newstats.countIdentifier = elt.count;
          if (elt._id === "NEW_MUSLIM") newstats.countNewMuslim = elt.count;
        });
        setstats(newstats);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <div className="home-container">
      <div className="home-header">
        <div className="header-elements">
          <i className="fa fa-user" />
          <div className="users-number">
            <h1>{stats.total}</h1>
            <span className="name-user">User</span>
          </div>
        </div>
      </div>
      <Container>
        <div className="cards-container">
          <StatsCard
            image={Advocacy}
            title="Advocacy"
            number={Object.keys(stats).length === 0 ? "0" : stats.countAdvocate}
          />
          <StatsCard
            image={Identifier}
            title="Identifier"
            number={
              Object.keys(stats).length === 0 ? "0" : stats.countIdentifier
            }
          />
          <StatsCard
            image={nonMuslims}
            title="new-muslims"
            number={
              Object.keys(stats).length === 0 ? "0" : stats.countNewMuslim
            }
          />
        </div>
        <h2 className="title-advocacy">Advocacy counter</h2>
        <div className="advocacy-counter">
          <div className="progress-points">
            <img src={ImgCoup} alt="" />
            <h4 className="title-point">1000 Point</h4>
          </div>
          <div className="progress-points progress-stars">
            <img src={ImgStars} alt="" />
            <h4 className="title-point">670 Point</h4>
          </div>
          {/* <div className="star-points">{props.starPoints || 0} Point</div> */}
        </div>
      </Container>
    </div>
  );
};

export default Home;
