import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../../utils/api";
import HttpHandlerHoc from "../../HOC/httpHandlerHoc";
import ImgProfile from "../../../assets/img/profile.png";
import { transformDate } from "../../../utils";

const DialogueDetail = () => {
  const [blog, setBlog] = useState({});
  const { id } = useParams();
  useEffect(() => {
    api
      .get(`blog/${id}`, {
        params: {
          populateuser: true,
        },
      })
      .then((response) => {
        setBlog(response.data.blog);
      })
      .catch((e) => toast.error(e.message));
  }, [id]);
  return (
    <>
      <div className="p-4">
        <div className="w-100 d-flex align-items-center">
          <div className="w-100 d-flex align-items-center mb-5">
            <img
              src={blog?.owner?.profile_photo || ImgProfile}
              alt={blog?.owner?.name}
              style={{ width: 100, height: 100 }}
            />
            <p className="p-0 m-0 ms-4">{blog?.owner?.name}</p>
          </div>
          <p style={{ marginRight: "auto" }}>
            {transformDate(blog?.createdAt)}
          </p>
        </div>

        <div dangerouslySetInnerHTML={{ __html: blog?.content }} />
      </div>
    </>
  );
};

export default DialogueDetail;
