import iconHome from "../assets/img/icons/home.png";
import iconProfile from "../assets/img/icons/profile.png";
import iconLang from "../assets/img/icons/lang.png";
import iconSuspicions from "../assets/img/icons/suspicions.png";
import iconDialogue from "../assets/img/icons/dialogue.png";
import iconReport from "../assets/img/icons/report.png";
import iconIvit from "../assets/img/icons/invit.png";
import iconEducation from "../assets/img/icons/education.png";

const routes = [
  {
    path: `/`,
    title: "Home",
    src: iconHome,
  },
  {
    path: `/profile`,
    title: "Profile",
    src: iconProfile,
  },
  {
    path: `/channel`,
    title: "Add Channel",
    src: iconLang,
  },
  {
    path: `/edit-channel`,
    title: "Edit Channel",
    src: iconLang,
  },
  {
    path: `/courses`,
    title: "Courses",
    src: iconLang,
  },
  {
    path: "/inquiry",
    title: "Responding to Filter",
    src: iconSuspicions,
  },
  {
    path: `/respone-suspicions`,
    title: "Responding to suspicions",
    src: iconSuspicions,
  },
  {
    path: `/dialogue`,
    title: "Dialogue Methodes",
    src: iconDialogue,
  },
  {
    path: `/quiz`,
    title: "Quiz",
    src: iconLang,
  },
  {
    path: `/requested-users`,
    title: "Requested users",
    src: iconReport,
  },
  {
    path: `/report/advocate`,
    title: "Report Advocate",
    src: iconReport,
  },
  {
    path: `/report/identifier`,
    title: "Report Identifier",
    src: iconReport,
  },
  {
    path: `/report/teacher`,
    title: "Report Teacher",
    src: iconReport,
  },
  {
    path: `/invitation`,
    title: "Invitation to new-muslims",
    src: iconIvit,
  },
  {
    path: `/new-muslims`,
    title: "New Muslims",
    src: iconReport,
  },
  {
    path: `/education`,
    title: "Education",
    src: iconEducation,
  },
];

export default routes;
