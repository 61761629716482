import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'

import languages from '../../../constants/languages';

import Activity from "./Tabs/Activity";



const tabProfile = (props) => {
  return (
    <div className='tabProfile'>
        <Tabs defaultActiveKey="activity" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="activity" title="Acitivity">
                <Activity languages={languages} suspicions={props.questions} />
            </Tab>
        </Tabs>
    </div>
  )
}

export default tabProfile