import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { AxiosWithAuth } from "../../../context/axios";
import AddInputActivity from "./AddInputActivity";
import "./QuizItem.scss";
const QuizItem = ({ lang }) => {
  const [q, setQ] = useState("");
  const [quizType, setQuizType] = useState("more");
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    (quiz) => {
      return AxiosWithAuth().post("quizes", quiz);
    },
    {
      onSuccess: () => {
        setQ("");
        setData([]);
        toast.success("quiz added succesfully");
        queryClient.invalidateQueries(["quizes"]);
      },
      onError: () => {
        toast.error("problem creating your quiz");
      },
    }
  );
  const [data, setData] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    mutate({
      quizType,
      question: q,
      answer: data,
      lang,
    });
  };
  return (
    <Form
      className="form-quiz quizItem mb-5 p-2 rounded"
      onSubmit={handleSubmit}
      style={{ backgroundColor: "#D2E8FF" }}>
      <Row className="justify-content-between">
        <Col sm={11}>
          <Row className="row-answer search justify-content-between">
            <Form.Control
              type="text"
              placeholder="Question"
              className="input-search"
              onChange={(e) => setQ(e.target.value)}
              value={q}
              required={true}
            />
          </Row>
          <Row className="column-list-answer justify-content-between align-items-center my-3">
            <Col
              controlId="formRoel"
              className="colun-role-quiz"
              xs={"auto"}
              md="4">
              <Button
                className="btn-role-quiz"
                variant={quizType === "one" ? "primary" : "default"}
                onClick={() => setQuizType("one")}>
                <i className="fa fa-circle-o icon-circle"></i>
                One answer
              </Button>
            </Col>
            <Col className="colun-role-quiz" xs={"auto"} md="4">
              <Button
                variant={quizType === "more" ? "primary" : "default"}
                className="btn-role-quiz"
                onClick={() => setQuizType("more")}>
                <i className="fa fa-circle icon-circle"></i>
                Multiple choices
              </Button>
            </Col>
          </Row>
          <Row className="column-list-answer justify-content-between">
            {quizType === "one" ? (
              <Form.Control
                id="answer"
                name="answer"
                type="text"
                placeholder="Answer"
                className="input-answer"
                onChange={(e) => setData([{ [e.target.name]: e.target.value }])}
                value={data[0]?.answer || ""}
                required={true}
              />
            ) : (
              <AddInputActivity setData={setData} loading={isLoading} />
            )}
          </Row>
        </Col>
        <Col sm="1" className="buttons">
          <Button
            variant="success"
            className="action-btn"
            type="submit"
            disabled={isLoading}>
            <i class="fa fa-check"></i>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default QuizItem;
