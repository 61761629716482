import React, { useEffect, useState } from "react";
import ItemEducation from "./ItemTeachers";
import { Button, Container, Modal, Row } from "react-bootstrap";
import "./Teachers.scss";
import { api } from "../../../utils/api";
import FilterModal from "../../shared/filterModal";
import { toast } from "react-toastify";

const Education = () => {
  const [users, setUsers] = useState([]);
  const [changes, handleChanges] = useState(false);
  const [filtredData, setFiltredData] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    (async () => {
      const { data } = await api.get("users/all", {
        params: {
          role: "TEACHER",
          accepted: true,
        },
      });
      setUsers(data.result.users);
      setFiltredData(data.result.users);
    })();
  }, [changes]);
  return (
    <>
      <div className="students education content-grid">
        <div className="listStudent listDialogue">
          <div className="header-page">
            <Container>
              <div className="content-header-page">
                <h2 className="title-page">All Teachers</h2>
                <div className="d-flex gap-4 align-items-center">
                  <Button variant="primary" onClick={() => setModalShow(true)}>
                    Filter
                  </Button>
                  <h3 style={{ alignSelf: "end" }}>
                    Result: {filtredData.length}
                  </h3>
                </div>
              </div>
            </Container>
          </div>
          <div className="list-box">
            <Container>
              <Row className="row-list-dialogue">
                {filtredData.map((user) => (
                  <ItemEducation
                    user={user}
                    key={user._id}
                    handleChanges={handleChanges}
                  />
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <FilterModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={users}
        setData={setFiltredData}
      />
    </>
  );
};

export default Education;
