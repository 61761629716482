import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import languageIcon from "../../../../assets/img/home/language-icon.png";
import chatIcon from "../../../../assets/img/home/green-chat.png";

const Activity = (props) => {
  const navigate = useNavigate();
  return (
    <div className="activityProfile">
      <Container>
        <Row className="row-activity">
          <Col className="column-quiz">
            <div className="list-quizes">
              <div className="header-profile-body">
                <Link to="/insert-language" className="btn-add">
                  <i className="fa fa-plus"></i>
                </Link>
                <h4 className="title-header-content">Quizzes</h4>
              </div>
              <div className="items-quiz">
                {props.languages &&
                  props.languages.slice(0, 3).map((lang, index) => (
                    <div className="content-language-item" key={index}>
                      <div className="level">
                        <img src={languageIcon} alt="language" />
                        <h4 className="name-lang">{lang} level</h4>
                      </div>
                      <div className="action-lang">
                        <Link to={`/add-quiz/${lang}`} className="link-action">
                          <i className="fa fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Col>
          <Col className="column-suspicions">
            <div className="list-quizes">
              <div className="header-profile-body">
                <Link to="/addquestion-suspicions" className="btn-add">
                  <i className="fa fa-plus"></i>
                </Link>
                <h4 className="title-header-content">
                  Responding to suspicions
                </h4>
              </div>
              <div className="items-quiz">
                {props.suspicions &&
                  props.suspicions.map((question, index) => (
                    <div className="content-language-item" key={index}>
                      <div className="level">
                        <img src={chatIcon} alt="language" />
                        <h4 className="name-lang">{question.title}</h4>
                      </div>
                      <div className="action-lang">
                        <i
                          onClick={() => {
                            navigate("/addquestion-suspicions", {
                              state: question,
                            });
                          }}
                          className="fa fa-angle-right"
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Activity;
