import React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const DialogueItem = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className="dialogue-item-container p-2 w-100"
      style={{ maxWidth: 900 }}>
      {/* <iframe
        width={160}
        height={130}
        src={props.dialogue.video}
        title={props.dialogue.title}
      /> */}
      <img
        src={props.dialogue.photo}
        alt=""
        className="img-dialogue"
        style={{ width: 160, height: 130, objectFit: "contain" }}
      />
      <div className="px-4 py-1 pt-5 text-end" style={{ flex: 1 }}>
        <h4 className="title-content">{props.dialogue.title}</h4>
        <p
          className="text-truncate"
          dangerouslySetInnerHTML={{ __html: props.dialogue.body }}></p>
      </div>
      <div className="action-dialogue">
        <Link
          to={{
            pathname: "",
          }}
          onClick={(e) => {
            e.preventDefault();
            navigate("/add-dialogue", { state: props.dialogue });
          }}
          className="button-edit btn-action">
          <i className="button-edit btn-action fa fa-pencil icon-btn-edit icon-btn" />
          Edit
        </Link>
        <Button
          onClick={() => {
            props.onDelete(props.dialogue._id);
          }}
          variant="dafault"
          className="button-delete btn-action">
          <i className="fa fa-trash icon-btn-delete icon-btn" />
          Delete
        </Button>
      </div>
    </div>
  );
};

export default DialogueItem;
