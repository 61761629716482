import React from "react";
import LanguageItem from "./LanguageItem";
import "./SetLanguage.scss";
import { LANG } from "../../../utils";

const SetLanguage = (props) => {
  const languageItems = Object.entries(LANG).map(([key, value]) => (
    <LanguageItem label={value} lang={key} key={key} />
  ));

  return languageItems;
};

export default SetLanguage;
