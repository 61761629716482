import React from "react";
import { useNavigate } from "react-router-dom";

import Input from "../../shared/input";
import userIcon from "../../../assets/img/icons/login.png";
import userPassword from "../../../assets/img/icons/password.png";

import "./Login.scss";
import { useState } from "react";
import { api } from "../../../utils/api";
import { Container, Spinner, Toast, Form, Row, Col } from "react-bootstrap";
import { useAuth } from "../../HOC/AuthContext";
import { useEffect } from "react";

const LoginPage = (props) => {
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [emailError, setemailError] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isloading, setisloading] = useState(false);
  const { updateAuth, user } = useAuth();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);
  async function onLogin() {
    try {
      setLoginError("");
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      setemailError(
        !email
          ? "Email field is required"
          : !emailRegex.test(email)
          ? "Invalid email adress"
          : ""
      );
      setpasswordError(!password ? "Password field is required" : "");
      if (!email || !password || !emailRegex.test(email)) return;
      setisloading(true);
      const { data } = await api.post("/user/admin/login", {
        email: email,
        password: password,
      });
      setisloading(false);
      updateAuth(data.token, data.user);
      navigate("/", { replace: true });
    } catch (e) {
      setisloading(false);
      setLoginError("Unable to connect, please verify your credentials");
    }
  }
  return (
    <div className="login-page">
      <Container>
        <h1 className="login-label">Admin login</h1>
        <Form>
          <Row className="row-form-login">
            <Col md={12}>
              <Input
                iconImage={userIcon}
                imageClass="login-icon"
                onChange={(event) => {
                  setemail(event.target.value);
                }}
                className={"login-input" + (emailError ? " input-error" : "")}
                placeholder="Login Page"
              />
            </Col>
            <Col md={12}>
              <Input
                iconImage={userPassword}
                imageClass="password-icon"
                onChange={(event) => {
                  setpassword(event.target.value);
                }}
                type="password"
                className={
                  "password-input" + (passwordError ? " input-error" : "")
                }
                placeholder="Password"
              />
            </Col>
            {!isloading ? (
              <button onClick={onLogin} className="btn-login">
                Enter
              </button>
            ) : (
              <div className="login-spinner">
                {[...Array(7).keys()].map((key) => (
                  <Spinner
                    size="sm"
                    key={key}
                    animation="grow"
                    style={{ color: "#00376f" }}
                  />
                ))}
              </div>
            )}
          </Row>
        </Form>

        <div className="icons-group">
          <i className="fa fa-cog">
            <span>settings</span>{" "}
          </i>
          <i className="fa fa-info">
            <span>about</span>{" "}
          </i>
          <i className="fa fa-question">
            <span>Q&a</span>{" "}
          </i>
        </div>
      </Container>
      <Toast
        show={loginError !== ""}
        style={{
          position: "absolute",
          top: 0,
          color: "white",
          left: 5,
          fontWeight: "bold",
          fontSize: 17,
        }}
        className="d-inline-block m-1"
        bg={"danger"}>
        <Toast.Body className={"danger"}>{loginError}</Toast.Body>
      </Toast>
    </div>
  );
};

export default LoginPage;
