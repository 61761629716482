import React, {useState} from 'react'
import {Button} from "react-bootstrap";

import ModalAddAdmin from './ModalAddAdmin';
import ModalAddUser from './ModalAddUser';

const ButtonAdd = () => {
    const   [showuser, setShowuser] = useState(false),
            [showadmin, setShowadmin] = useState(false);

    const   handleCloseUser = () => setShowuser(false),
            handleShowUser = () => {setShowadmin(false);setShowuser(true)},
            handleCloseAdmin = () => setShowadmin(false),
            handleShowAdmin = () => {setShowadmin(true);setShowuser(false)};
    return (
        <div className="list-btn-add">
            <Button variant="default" className='btn-add-admin btn-add' onClick={handleShowUser}>
                <i className='fa fa-plus icon-plus'></i>
                <h4 className='title-btn-add'>Add Admin</h4>
            </Button>
            <Button variant="default" className='btn-add-user btn-add' onClick={handleShowAdmin}>
                <i className='fa fa-plus icon-plus'></i>
                <h4 className='title-btn-add'>Add User</h4>
            </Button>

            <ModalAddAdmin handleClose={handleCloseUser} show={showuser} />
            <ModalAddUser handleClose={handleCloseAdmin} show={showadmin} />
            
        </div>
    )
}

export default ButtonAdd