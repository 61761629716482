import React from "react";
import imgDialoge from "../../../assets/img/dialogue.png";
import { Button, Card, Stack } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosWithAuth } from "../../../context/axios";
import { toast } from "react-toastify";

const Category = ({ _id, title, count }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    (category) => {
      return AxiosWithAuth().delete(`courses/${category}`);
    },
    {
      onSuccess: () => {
        toast.success("category deleted succesfully");
        queryClient.invalidateQueries(["categories"]);
      },
      onError: () => {
        toast.error("problem deleting category");
      },
    }
  );
  return (
    <div className="column-dialogue col-lg-6 col-md-6 col-sm-12">
      <Card style={{ alignItems: "center" }}>
        <Card.Img
          onClick={() => navigate(_id)}
          className="media-dialogue"
          variant="top"
          src={imgDialoge}
        />
        <Card.Body>
          <div className="content-body w-100">
            <Link to={_id}>
              <Card.Title style={{ textTransform: "capitalize" }}>
                {title}
              </Card.Title>
              <p className="number-course">
                <strong>Number Course: </strong>
                {count}
              </p>
            </Link>
          </div>
          <Stack className="py-3">
            <Link
              to={_id}
              className="btn btn-primary btn-add rounded-circle d-flex align-items-center justify-content-center"
              style={{ width: 45, height: 45 }}>
              <i className="fa fa-plus"></i>
            </Link>
            {/* delete button */}
            <Button
              variant="danger"
              onClick={() => mutate(_id)}
              className="rounded-circle d-flex align-items-center justify-content-center mt-2"
              style={{ width: 45, height: 45 }}>
              <i className="fa fa-trash" style={{ fontSize: 20 }}></i>
            </Button>
          </Stack>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Category;
