import React, { useState, useEffect } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import AddAudio from "./AddAudio";
import AddComment from "./AddComment";
import AddImage from "./AddImage";
import AddVideo from "./AddVideo";
import "./Channel.scss";

export default function Channel() {
  return (
    <div className="channel">
      <Container>
        <h1 className="title-page">Manage Channel</h1>
      </Container>
      <div className="channel-content">
        {/**************** Audios ******************** */}
        <AddAudio />
        {/**************** Images ******************** */}
        <div className="devider"></div>
        <AddImage />
        {/**************** Videos ******************** */}
        <div className="devider"></div>
        <AddVideo />
        {/**************** Comments ******************** */}
        <div className="devider"></div>
        <AddComment />
      </div>
    </div>
  );
}
