import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { Container, Button, Row, Col, Modal, Form } from "react-bootstrap";
import ImgUser from "../../../assets/img/icons/user1.png";
import { transformDate } from "../../../utils";
import "./Report.scss";
import { api } from "../../../utils/api";
import HttpHandlerHoc from "../../HOC/httpHandlerHoc";
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const ReportsUser = (props) => {
  const location = useLocation();
  const [monthlyActivity, setMonthlyActivity] = useState([]);
  const [dailyActivity, setDailyActivity] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [mode, setMode] = useState("DAILY");

  const navigate = useNavigate();
  function onDelete() {
    handleShow();
  }
  async function confirmDelete() {
    try {
      await api.delete("/user/" + location.state.id);
      navigate(-1);
    } catch (e) {}
  }
  const { user } = useParams();
  useEffect(() => {
    (async () => {
      const { data: months } = await api.get("activity/month", {
        params: {
          user,
        },
      });
      const { data: daily } = await api.get("activity", {
        params: {
          user,
        },
      });
      setMonthlyActivity(months);
      setDailyActivity(daily.weeks);
    })();
  }, [user]);
  return (
    <>
      <div className="reportsUser report">
        <div className="header-page header-report">
          <Container>
            <div className="content-header-page">
              <div className="info-user">
                <img src={ImgUser} alt="" width={100} />
                <div className="info">
                  <h4 className="name-user">{location.state.name}</h4>
                  <h4 className="mx-4" style={{ color: "gray" }}>
                    {location.state.role}
                  </h4>
                </div>
              </div>
              {/* <Button
                onClick={onDelete}
                variant="dafault"
                className="action-header">
                <i className="fa fa-trash icon-header"></i>
              </Button> */}
              <Form.Select
                size="md"
                style={{ width: 200 }}
                onChange={(e) => setMode(e.target.value)}
              >
                <option>DAILY</option>
                <option>MONTHLY</option>
              </Form.Select>
            </div>
          </Container>
        </div>
        <div className="reportsUser-items reportItem">
          <Container>
            <div className="content-report">
              {mode === "DAILY" ? (
                <Row className="row-report">
                  {dailyActivity?.map((activity, index) => (
                    <Col className="column-report col-md-6 col-lg-6">
                      <div className="box-user">
                        <Link to={`${activity._id}`} className="details-user">
                          <div className="info-user">
                            <h4 className="name-user text-capitalize">
                              Day {index + 1}
                            </h4>
                            {/* <p className="role-user text-capitalize">
                              DATE: {transformDate(activity.createdAt)}
                            </p> */}
                          </div>
                        </Link>
                        <div className="action-report">
                          <Link
                            to={`${activity._id}`}
                            className="btn-action"
                            variant="default"
                          >
                            <i className="fa fa-eye icon-button"></i>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row className="row-report">
                  {monthlyActivity.map((activity, index) => (
                    <Col className="column-report col-md-6 col-lg-6">
                      <div className="box-user">
                        <Link
                          to={`${activity._id.year}/${activity._id.month}`}
                          className="details-user"
                        >
                          <div className="info-user">
                            <h4 className="name-user text-capitalize">
                              Day {index + 1}: {MONTHS[activity._id.month - 1]}
                            </h4>
                            <p className="role-user text-capitalize">
                              work days: {activity.total}
                            </p>
                          </div>
                        </Link>
                        <div className="action-report">
                          <Link
                            to={`${activity._id.year}/${activity._id.month}`}
                            className="btn-action"
                            variant="default"
                          >
                            <i className="fa fa-eye icon-button"></i>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Remove user confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                You are about to delete this user, Are you sure about that?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="success" onClick={confirmDelete}>
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ReportsUser;
