import React, { useState } from "react";
import ItemNewMuslim from "./ItemNewMuslim";
import { Button, Container, Row } from "react-bootstrap";
import { useEffect } from "react";
import { api } from "../../../utils/api";
import FilterModal from "../../shared/filterModal";
import axios from "axios";

const NewMuslim = () => {
  const [users, setUsers] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [changes, handleChanges] = useState(false);
  const [filtredData, setFiltredData] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isRequesting, setRequesting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [cancelToken, setCancelToken] = useState(null);

  useEffect(() => {
    async function getTeachers() {
      try {
        const res = await api.get("users/all", {
          params: {
            role: "TEACHER",
            accepted: true,
          },
        });
        if ((res?.status == 200 || res?.status == 201) && res?.data) {
          setTeachers(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getTeachers();
  }, []);

  async function getData(page) {
    setLoading(false);
    setRequesting(true);
    // Create a new cancel token for the API request
    const source = axios.CancelToken.source();
    try {
      const responseNEW_MUSLIM = await api.get("users", {
        params: {
          role: "NEW_MUSLIM",
          // haveTeacher: false,
          accepted: false,
          page,
        },
        cancelToken: source.token, // Assign the cancel token to the request
      });
      const { data, currentPage, totalPages, totalUsers } =
        responseNEW_MUSLIM.data;
      setUsers(data);
      setFiltredData(data);

      setCurrentPage(currentPage);
      setTotalPages(totalPages);
      setTotalCount(totalUsers);
    } catch (error) {
      if (axios.isCancel(error)) {
        // Ignore canceled requests
        console.log("Request canceled", error.message);
      } else {
        console.log("Failed to fetch users: ", error);
      }
    } finally {
      setLoading(false);
      setRequesting(false);
    }
  }

  useEffect(() => {
    getData(currentPage);
    return () => {
      // Cancel the API request when the component unmounts
      if (cancelToken) {
        cancelToken.cancel("Request canceled");
      }
    };
  }, [changes, currentPage]);

  // Pagination event handlers
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const usersPerPage = 10; // Number of users to display per page

  // Calculate the line number based on the current page and users per page
  const calculateLineNumber = (index) => {
    return (currentPage - 1) * usersPerPage + index + 1;
  };

  // Pagination event handlers
  const goToPreviousPage = () => {
    if (!isRequesting && currentPage > 1) {
      // Add check for isRequesting
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (!isRequesting && currentPage < totalPages) {
      // Add check for isRequesting
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <div className="students education content-grid">
        <div className="listStudent listDialogue">
          <div className="header-page">
            <Container>
              <div className="content-header-page">
                <h2 className="title-page">New Muslim</h2>
                <div className="d-flex gap-4 align-items-center">
                  <Button variant="primary" onClick={() => setModalShow(true)}>
                    Filter
                  </Button>
                  <h3 style={{ alignSelf: "end" }}>Result: {totalCount}</h3>
                </div>
              </div>
            </Container>
          </div>
          <div className="list-box">
            <Container>
              <Row className="row-list-dialogue">
                {isLoading
                  ? "loading"
                  : filtredData.map((user) => (
                      <ItemNewMuslim
                        user={user}
                        key={user.id}
                        handleChanges={handleChanges}
                        teachers={teachers}
                      />
                    ))}
              </Row>
            </Container>
          </div>
          <div className="pagination m-3">
            <Button disabled={currentPage === 1} onClick={goToPreviousPage}>
              Previous
            </Button>
            <span>{`Page ${currentPage} of ${totalPages}`}</span>
            <Button
              disabled={currentPage === totalPages}
              onClick={goToNextPage}
            >
              Next
            </Button>
          </div>
        </div>
        <FilterModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          data={users}
          setData={setFiltredData}
        />
      </div>
    </>
  );
};

export default NewMuslim;
