import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import OldQuizes from "./oldQuizes";

import QuizItem from "./quizItem";

const Quizes = () => {
  const { lang } = useParams();
  return (
    <div className="addQuiz">
      <div className="header-page header-lang">
        <Container>
          <div className="content-header-page">
            <h2 className="title-page">Add Quiz</h2>
          </div>
        </Container>
      </div>
      <Container style={{ direction: lang !== "ar" ? "ltr" : "rtl" }}>
        <QuizItem lang={lang} />
        <OldQuizes />
      </Container>
    </div>
  );
};

export default Quizes;
