import React from "react";
import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Question = ({
  answer,
  question: { user, body: qbody },
  answered,
  _id,
}) => {
  return (
    <Col as={Link} to={_id} sm={12} md={6}>
      <Card className="my-4">
        <Card.Header
          className="text-center"
          style={{
            backgroundColor: answer ? "#185550" : "#D88EB8",
            color: "white",
          }}>
          {user.name}
        </Card.Header>
        <Card.Body className="text-left" style={{ color: "black" }}>
          <Card.Title
            className="d-inline-block text-truncate"
            style={{ width: "100%" }}>
            {qbody}
          </Card.Title>
          <Card.Text>
            <p>{answered ? answer.body : "No Answer Yet"}</p>
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">2 days ago</Card.Footer>
      </Card>
    </Col>
  );
};

export default Question;
