import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";

import ReportItem from "./ReportItem";
import ImgProfile from "../../../assets/img/default_user.png";
import { api } from "../../../utils/api";
import FilterModal from "../../shared/filterModal";

const RequestedUsers = () => {
  const [users, setusers] = useState([]);
  const [filtredData, setFiltredData] = useState([]);
  const [change, setChange] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/users/requested");
        setusers(data.data);
        setFiltredData(data.data);
      } catch (e) {}
    })();
  }, [change]);

  return (
    <>
      <div className="report">
        <div className="header-page header-report">
          <Container>
            <div className="content-header-page">
              <h2 className="title-page">Requested Users</h2>
              <Button variant="primary" onClick={() => setModalShow(true)}>
                Filter
              </Button>
            </div>
          </Container>
        </div>
        <div className="reportItem">
          <Container>
            <div className="content-report">
              <Row className="row-report">
                {filtredData.map((user) => (
                  <ReportItem
                    key={user._id}
                    setChange={setChange}
                    id={user._id}
                    img={user.profile_photo || ImgProfile}
                    name={user.name}
                    role={user.role}
                  />
                ))}
              </Row>
            </div>
          </Container>
        </div>
        <FilterModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          data={users}
          setData={setFiltredData}
        />
      </div>
    </>
  );
};

export default RequestedUsers;
