import React, { useState, useEffect } from "react";
import { Button, Container, Form, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { api } from "../../../utils/api";
import "./editChannel.scss";

export default function EditChannel() {
  const [isLoading, setIsLoading] = useState(true);
  const [audios, setAudios] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [comments, setComments] = useState([]);
  const headers = ["Title", "Created At", "Actions"];

  async function getData() {
    try {
      const res = await api.get("channel");
      if (res.status == 200) {
        setAudios(res.data.audios);
        setImages(res.data.images);
        setVideos(res.data.videos);
        setComments(res.data.comments);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function handleDelete(id, path) {
    try {
      const res = await api.post(`channel/${path}/delete/${id}`);
      if (res.status == 200 || res.status == 201) {
        getData();
        toast.success("deleted successfuly");
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("something went wrong");
      setIsLoading(false);
    }
  }

  const convertDate = (date) => {
    var created_date = new Date(date);

    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = created_date.getFullYear();
    var month = months[created_date.getMonth()];
    var date = created_date.getDate();
    var hour = created_date.getHours();
    var min = created_date.getMinutes();
    var sec = created_date.getSeconds();
    var time =
      date + "," + month + " " + year + " " + hour + ":" + min + ":" + sec;
    return time;
  };

  useEffect(() => {
    getData();
  }, []);
  if (isLoading) {
    return <div>loading</div>;
  }
  return (
    <div className="channel">
      <Container>
        <h1 className="title-page">Manage Channel</h1>
      </Container>
      <div className="channel-content">
        <Container>
          <div className="table-title">Manage Audios</div>
          <Table striped bordered hover responsive>
            <thead>
              <tr style={{ backgroundColor: "#e3b25f", color: "white" }}>
                {headers?.map((title, index) => (
                  <th
                    key={title}
                    style={{
                      fontSize: "0.7rem",
                    }}
                    className="text-center  "
                  >
                    <p
                      className="text-truncate"
                      style={{
                        margin: "0 auto",
                      }}
                    >
                      {title}
                    </p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {audios.map((el, i) => (
                <tr key={`audio-${i}`}>
                  <td
                    style={{
                      fontSize: "0.7rem",
                      width: "100%",
                    }}
                    className="text-center"
                  >
                    <p
                      className="text-truncate"
                      style={{
                        margin: "0 auto",
                      }}
                    >
                      {el.title}
                    </p>
                  </td>
                  <td
                    style={{
                      fontSize: "0.7rem",
                      width: "100%",
                    }}
                    className="text-center"
                  >
                    <p
                      className="text-truncate"
                      style={{
                        margin: "0 auto",
                      }}
                    >
                      {convertDate(el.createdAt)}
                    </p>
                  </td>
                  <td
                    style={{
                      fontSize: "0.7rem",
                    }}
                    className="text-center"
                  >
                    <div
                      className="delete-btn"
                      onClick={() => handleDelete(el._id, "audios")}
                    >
                      delete
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
        <div className="devider"></div>
        <Container>
          <div className="table-title">Manage Images</div>
          <Table striped bordered hover responsive>
            <thead>
              <tr style={{ backgroundColor: "#e3b25f", color: "white" }}>
                {headers?.map((title, index) => (
                  <th
                    key={title}
                    style={{
                      fontSize: "0.7rem",
                    }}
                    className="text-center  "
                  >
                    <p
                      className="text-truncate"
                      style={{
                        margin: "0 auto",
                      }}
                    >
                      {title}
                    </p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {images.map((el, i) => (
                <tr key={`image-${i}`}>
                  <td
                    style={{
                      fontSize: "0.7rem",
                      width: "100%",
                    }}
                    className="text-center"
                  >
                    <p
                      className="text-truncate"
                      style={{
                        margin: "0 auto",
                      }}
                    >
                      {el.title}
                    </p>
                  </td>
                  <td
                    style={{
                      fontSize: "0.7rem",
                      width: "100%",
                    }}
                    className="text-center"
                  >
                    <p
                      className="text-truncate"
                      style={{
                        margin: "0 auto",
                      }}
                    >
                      {convertDate(el.createdAt)}
                    </p>
                  </td>
                  <td
                    style={{
                      fontSize: "0.7rem",
                    }}
                    className="text-center"
                  >
                    <div
                      className="delete-btn"
                      onClick={() => handleDelete(el._id, "images")}
                    >
                      delete
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
        <div className="devider"></div>
        <Container>
          <div className="table-title">Manage Videos</div>
          <Table striped bordered hover responsive>
            <thead>
              <tr style={{ backgroundColor: "#e3b25f", color: "white" }}>
                {headers?.map((title, index) => (
                  <th
                    key={title}
                    style={{
                      fontSize: "0.7rem",
                    }}
                    className="text-center  "
                  >
                    <p
                      className="text-truncate"
                      style={{
                        margin: "0 auto",
                      }}
                    >
                      {title}
                    </p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {videos.map((el, i) => (
                <tr key={`video-${i}`}>
                  <td
                    style={{
                      fontSize: "0.7rem",
                      width: "100%",
                    }}
                    className="text-center"
                  >
                    <p
                      className="text-truncate"
                      style={{
                        margin: "0 auto",
                      }}
                    >
                      {el.title}
                    </p>
                  </td>
                  <td
                    style={{
                      fontSize: "0.7rem",
                      width: "100%",
                    }}
                    className="text-center"
                  >
                    <p
                      className="text-truncate"
                      style={{
                        margin: "0 auto",
                      }}
                    >
                      {convertDate(el.createdAt)}
                    </p>
                  </td>
                  <td
                    style={{
                      fontSize: "0.7rem",
                    }}
                    className="text-center"
                  >
                    <div
                      className="delete-btn"
                      onClick={() => handleDelete(el._id, "videos")}
                    >
                      delete
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
        <div className="devider"></div>
        <Container>
          <div className="table-title">Manage Comments</div>
          <Table striped bordered hover responsive>
            <thead>
              <tr style={{ backgroundColor: "#e3b25f", color: "white" }}>
                {headers?.map((title, index) => (
                  <th
                    key={title}
                    style={{
                      fontSize: "0.7rem",
                    }}
                    className="text-center  "
                  >
                    <p
                      className="text-truncate"
                      style={{
                        margin: "0 auto",
                      }}
                    >
                      {title}
                    </p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {comments.map((el, i) => (
                <tr key={`comment-${i}`}>
                  <td
                    style={{
                      fontSize: "0.7rem",
                      width: "100%",
                    }}
                    className="text-center"
                  >
                    <p
                      className="text-truncate"
                      style={{
                        margin: "0 auto",
                      }}
                    >
                      {el.title}
                    </p>
                  </td>
                  <td
                    style={{
                      fontSize: "0.7rem",
                      width: "100%",
                    }}
                    className="text-center"
                  >
                    <p
                      className="text-truncate"
                      style={{
                        margin: "0 auto",
                      }}
                    >
                      {convertDate(el.createdAt)}
                    </p>
                  </td>
                  <td
                    style={{
                      fontSize: "0.7rem",
                    }}
                    className="text-center"
                  >
                    <div
                      className="delete-btn"
                      onClick={() => handleDelete(el._id, "comments")}
                    >
                      delete
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </div>
    </div>
  );
}
