import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Outlet, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosWithAuth } from "../../../context/axios";
import { slugToText } from "../../../utils";
import ModalAddCateg from "./ModalAddCateg";
import "./SetLanguage.scss";

export default function Courses() {
  const { category, lang, id } = useParams();

  const [news, setNews] = useState(true);
  const [show, setShow] = useState(false);
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(
    async (course) => {
      const data = await AxiosWithAuth().post(`/courses/${category}`, course);
      console.log(data);
    },
    {
      onSuccess: () => {
        toast.success("course added succesfully");
        queryClient.invalidateQueries("courses");
      },
      onError: (e) => {
        console.log(e);
        toast.error("problem creating your course xsls");
      },
    }
  );
  const handleClose = () => setShow(false),
    handleShow = () => {
      setShow(true);
    };
  const handleAdd = async (title) => {
    mutate({
      title,
    });
    setNews((p) => !p);
    handleClose();
  };
  return (
    <div className="languages-container">
      <div className="header-page header-lang">
        <Container>
          <div className="content-header-page">
            <h2 className="title-page">
              {id
                ? `Lessons`
                : !category
                ? "Courses Categories"
                : category
                ? `courses`
                : "Languages"}
            </h2>
            {category && (
              <Button
                onClick={handleShow}
                variant="default"
                className="btn-add">
                <i className="fa fa-plus"></i>
              </Button>
            )}
          </div>
        </Container>
      </div>
      <Outlet context={{ news }} />
      <ModalAddCateg
        handleClose={handleClose}
        show={show}
        heading={"Add Course"}
        placeHolder={"type your course title"}
        callback={handleAdd}
      />
    </div>
  );
}
