import React from "react";
import imgDialoge from "../../../assets/img/dialogue.png";
import { Button, Card, Stack } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosWithAuth } from "../../../context/axios";
import { toast } from "react-toastify";

const ListLevelCourses = ({ course }) => {
  const { category } = useParams();
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(
    (id) => {
      return AxiosWithAuth().delete(`courses/${category}/${id}`);
    },
    {
      onSuccess: () => {
        toast.success("course deleted succesfully");
        queryClient.invalidateQueries("courses");
      },
      onError: () => {
        toast.error("problem deleting course");
      },
    }
  );
  return (
    <div className="column-dialogue col-lg-6 col-md-6 col-sm-12">
      <Card>
        <Link to={course._id} state={{ course }}>
          <Card.Img className="media-dialogue" variant="top" src={imgDialoge} />
        </Link>
        <Card.Body>
          <div className="content-body w-100">
            <Link to={course._id} state={{ course }}>
              <Card.Title style={{ textTransform: "capitalize" }}>
                {course.title}
              </Card.Title>
              <p className="">
                Number of lessons:{"  "}
                <strong>{course.lessons.length}</strong>
              </p>
            </Link>
          </div>
          <Stack className="py-3">
            <Link
              to={course._id}
              state={{ course }}
              className="btn btn-primary btn-add rounded-circle d-flex align-items-center justify-content-center"
              style={{ width: 45, height: 45 }}>
              <i className="fa fa-plus"></i>
            </Link>
            {/* delete button */}
            <Button
              variant="danger"
              onClick={() => mutate(course._id)}
              className="rounded-circle d-flex align-items-center justify-content-center mt-2"
              style={{ width: 45, height: 45 }}>
              <i className="fa fa-trash" style={{ fontSize: 20 }}></i>
            </Button>
          </Stack>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ListLevelCourses;
