import React from "react";
import { useParams } from "react-router-dom";
import { AxiosWithAuth } from "../../../context/axios";
import OldQuizItem from "./oldQuizItem";
import { useQuery } from "@tanstack/react-query";
const OldQuizes = () => {
  const { lang } = useParams();
  const { isLoading, isError, data, error } = useQuery(
    ["quizes", { lang }],
    () => AxiosWithAuth().get("quizes", { params: { lang } })
  );
  if (isLoading) {
    return <span>Loading...</span>;
  }
  if (isError) {
    return <span>Error: {error.message}</span>;
  }
  return (
    <>
      {data.data.quizes.map((quiz) => (
        <OldQuizItem key={quiz._id} quiz={quiz} />
      ))}
    </>
  );
};

export default OldQuizes;
