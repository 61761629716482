import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";

export const CoursesContext = createContext();

const CoursesProvider = ({ children }) => {
  const [courses, setCourses] = useState([]);
  const addNewCourse = (title) => {
    setCourses(v => [...v,{title}])
  }
  const values = {
    courses,
    addNewCourse
  }
  return <CoursesContext.Provider value={values}>{children}</CoursesContext.Provider>;
};
export const useCourses = () => {
    const values = useContext(CoursesContext)
    return values
}
export default CoursesProvider
