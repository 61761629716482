import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Dropdown,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../../utils/api";

const ModalAddUser = (props) => {
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const [nameError, setnameError] = useState("");
  const [role, setrole] = useState("");
  const [email, setemail] = useState("");
  const [emailError, setemailError] = useState("");
  const [profilephoto, setprofilephoto] = useState("");
  const [password, setpassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [passwordvalidation, setpasswordvalidation] = useState("");
  const [passwordvalidationError, setpasswordvalidationError] = useState("");
  const [isloading, setisloading] = useState(false);
  async function handleSubmit(event) {
    event.preventDefault();
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    setnameError(!name ? "Name field is required" : "");
    setemailError(
      !email
        ? "Email field is required"
        : !emailRegex.test(email)
        ? "Invalid email adress"
        : ""
    );
    setPasswordError(!password ? "Password field is required" : "");
    setRoleError(!role ? "User role is required" : "");
    setpasswordvalidationError(
      password && passwordvalidation != password
        ? "Password confirmation mismatch"
        : ""
    );
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("name", name);
    formData.append("role", role);
    if (profilephoto) formData.append("profile_photo", profilephoto);
    setisloading(true);
    const data = await api.post("user", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (data.status === 200) {
      toast.success("Admin added successfully");
      props.handleClose();
      navigate("/report-result");
    } else {
      props.handleClose();
      toast.error(data.response.data.message);
    }
    setisloading(false);
  }

  return (
    <Modal show={props.show} onHide={props.handleClose} className="modal-add">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="body-add">
        <h4 className="title-modal">Add user</h4>
        <Form className="form-add">
          <div className="upload-photo"></div>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridName" className="search">
              <Form.Control
                onChange={(event) => setname(event.target.value)}
                type="text"
                placeholder="User name"
                className="input-search"
              />
              <span style={{ color: "red", marginLeft: 5 }}>{nameError}</span>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridEmail" className="search">
              <Form.Control
                onChange={(event) => setemail(event.target.value)}
                type="email"
                placeholder="Email"
                className="input-search"
              />
              <span style={{ color: "red", marginLeft: 5 }}>{emailError}</span>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group
              as={Col}
              controlId="formGridPassword"
              className="search">
              <Form.Control
                onChange={(event) => setpassword(event.target.value)}
                type="password"
                placeholder="Password"
                className="input-search"
              />
              <span style={{ color: "red", marginLeft: 5 }}>
                {passwordError}
              </span>
            </Form.Group>

            <Form.Group
              as={Col}
              controlId="formGridConfirmation"
              className="search">
              <Form.Control
                onChange={(event) => setpasswordvalidation(event.target.value)}
                type="password"
                placeholder="Confirmation"
                className="input-search"
              />
              <span style={{ color: "red", marginLeft: 5 }}>
                {passwordvalidationError}
              </span>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <Dropdown className="dropdown-add">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {role || "Role"}
                </Dropdown.Toggle>
                <span style={{ color: "red", marginLeft: 5 }}>{roleError}</span>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setrole("ADVOCATE");
                      setRoleError("");
                    }}
                    href="#/action-1">
                    Advocate (داعية)
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setrole("IDENTIFIER");
                      setRoleError("");
                    }}
                    href="#/action-2">
                    Identifier (معرف)
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setrole("TEACHER");
                      setRoleError("");
                    }}
                    href="#/action-3">
                    TEACHER (معلم)
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridName" className="search">
              <Form.Label style={{ fontWeight: "bold", fontSize: 20 }}>
                Profile photo
              </Form.Label>
              <Form.Control
                onChange={(event) => {
                  setprofilephoto(event.target.files[0]);
                }}
                accept=".png, .jpg, .jpeg"
                data-classButton="btn btn-primary"
                data-input="false"
                data-classIcon="icon-plus"
                data-buttonText="Your label here."
                type="file"
                className="input-search"
              />
            </Form.Group>
          </Row>
          <div className="footer-modal">
            {!isloading ? (
              <>
                <Button
                  variant="default"
                  className="btn-return"
                  onClick={props.handleClose}>
                  Return
                </Button>
                <Button
                  variant="primary"
                  className="btn-add"
                  type="submit"
                  onClick={handleSubmit}>
                  Add
                </Button>
              </>
            ) : (
              <div>
                {[...Array(5).keys()].map((key) => (
                  <Spinner key={key} animation="grow" variant="info" />
                ))}
              </div>
            )}
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAddUser;
