import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { AxiosWithAuth } from "../../../context/axios";

export default function AddVideo() {
  const [videoTitle, setVideoTitle] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  const addVideo = async () => {
    if (!videoTitle) {
      toast.error("please add title for video");
    } else if (!videoFile) {
      toast.error("please add video file");
    } else {
      let formData = new FormData();
      formData.append("title", videoTitle);
      formData.append("post_video", videoFile);
      try {
        const res = await AxiosWithAuth().put("/channel/videos/add", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (res.status == 200 || res.status == 201) {
          toast.success("added new video to the Channel");
          setVideoTitle("");
          setVideoFile(null);
        }
      } catch (error) {
        if (error.response.status == 400 || error.response.status == 422) {
          toast.error(error.response.data.message);
        } else {
          toast.error("something went wrong");
        }
      }
    }
  };
  return (
    <div className="file-input-container">
      <div className="input-label">Video title</div>
      <Form.Control
        type="text"
        placeholder="video title"
        className="input-search input-title"
        name="video-title"
        onChange={(e) => setVideoTitle(e.target.value)}
        value={videoTitle}
        required={true}
      />

      <div className="input-label">Upload video</div>
      <input
        type="file"
        name="file-video"
        accept="video/*"
        className="input-file"
        onChange={(e) => setVideoFile(e.target.files[0])}
      />

      <Button
        type="button"
        className="btn btn-primary btn-add"
        onClick={addVideo}
      >
        Add
      </Button>
    </div>
  );
}
