import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { LANG } from "../../utils";

const ROLES = ["ADVOCATE", "NEW_MUSLIM", "TEACHER", "IDENTIFIER"];

const filterData = (filter, data) => {
  const { q, role, language, edate, sdate } = filter;

  let newData = data;
  if (q) {
    newData = newData.filter(
      (item) =>
        item.name.toLowerCase().includes(q.toLowerCase()) ||
        item.email.toLowerCase().includes(q.toLowerCase())
    );
  }
  if (role) {
    newData = newData.filter((item) => item.role === role);
  }
  if (language) {
    newData = newData.filter((item) => item.language === language);
  }
  if (edate) {
    newData = newData.filter((item) => item.createdAt <= edate);
  }
  if (sdate) {
    newData = newData.filter((item) => item.createdAt >= sdate);
  }
  return newData;
};
const FilterModal = ({ data, ...props }) => {
  const [filter, setFilter] = useState({
    edate: "",
    sdate: "",
    q: "",
    role: "",
    language: "",
  });
  const onFilter = () => {
    const filteredData = filterData(filter, data);
    console.log(data);
    props.setData(filteredData);
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="content-header-page">
            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}>
                <label htmlFor="search">Search:</label>
                <div className="search-input">
                  <input
                    id="search"
                    className="input-search"
                    placeholder="search"
                    type="text"
                    value={filter.q}
                    onChange={(e) =>
                      setFilter({ ...filter, q: e.target.value })
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}>
                <label htmlFor="role">Role:</label>
                <select
                  style={{ width: "200px" }}
                  onChange={(e) =>
                    setFilter({ ...filter, role: e.target.value })
                  }
                  value={filter.role}
                  id="role"
                  className="input-search">
                  <option value=""></option>
                  {ROLES.map((role, index) => (
                    <option key={index} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}>
                <label htmlFor="language">Language:</label>
                <select
                  style={{ width: "200px" }}
                  onChange={(e) =>
                    setFilter({ ...filter, language: e.target.value })
                  }
                  value={filter.language}
                  className="input-search"
                  placeholder="language">
                  <option value={null}></option>
                  {Object.entries(LANG).map(([abv, label]) => (
                    <option key={"language" + abv} value={abv}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}>
                <label htmlFor="s-date">Start date:</label>
                <input
                  id="s-date"
                  className="input-search"
                  type="date"
                  value={filter.sdate}
                  onChange={(e) =>
                    setFilter({ ...filter, sdate: e.target.value })
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}>
                <label htmlFor="e-date">End date:</label>
                <input
                  className="input-search"
                  type="date"
                  id="e-date"
                  value={filter.edate}
                  onChange={(e) =>
                    setFilter({ ...filter, edate: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
        <Button onClick={onFilter}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal;
