import axios from "axios";
import React, { useState } from "react";
import { Button, Modal, Form, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../../utils/api";

const ModalAddAdmin = (props) => {
  const [permissions, setpermissions] = useState({
    visitor: { value: true, label: "Visitor" },
    edit_delete_posts: { value: true, label: "Edit and delete postes" },
    add_postes: { value: true, label: "Add postes" },
    share_posts: { value: true, label: "Share postes" },
    delete_section: { value: true, label: "Delete section" },
    create_section: { value: true, label: "Create a new section" },
    add_language: { value: true, label: "Add language" },
    delete_language: { value: true, label: "Delete language" },
    create_sub_account: { value: true, label: "Create sub account" },
    delete_sub_account: { value: true, label: "Delete sub account" },
    general_reports: { value: true, label: "General reports" },
    general_stats: { value: true, label: "General stats" },
  });
  const [name, setname] = useState("");
  const [nameError, setnameError] = useState("");
  const [email, setemail] = useState("");
  const [emailError, setemailError] = useState("");
  const [password, setpassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordvalidation, setpasswordvalidation] = useState("");
  const [passwordvalidationError, setpasswordvalidationError] = useState("");
  const [profilephoto, setprofilephoto] = useState("");
  const [isloading, setisloading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    setnameError(!name ? "Name field is required" : "");
    setemailError(
      !email
        ? "Email field is required"
        : !emailRegex.test(email)
        ? "Invalid email adress"
        : ""
    );
    setPasswordError(!password ? "Password field is required" : "");
    setpasswordvalidationError(
      password && passwordvalidation != password
        ? "Password confirmation mismatch"
        : ""
    );
    if (
      !name ||
      !email ||
      !emailRegex.test(email) ||
      !password ||
      passwordvalidation !== password
    ) {
      toast.error("Please fill all the required fields");
      return;
    }
    setisloading(true);
    const perms = Object.keys(permissions).filter(
      (key) => permissions[key].value
    );
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("name", name);
    formData.append("permissions", JSON.stringify(perms));
    if (profilephoto) formData.append("profile_photo", profilephoto);
    const data = await api.post("user/admin", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (data.status === 200) {
      toast.success("Admin added successfully");
      props.handleClose();
      navigate("/report-result");
    } else {
      props.handleClose();
      toast.error(data.response.data.message);
    }
    setisloading(false);
  };
  return (
    <Modal show={props.show} onHide={props.handleClose} className="modal-add">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="body-add">
        <h4 className="title-modal">Add Admin</h4>
        <Form className="form-add">
          <div className="upload-photo"></div>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridName" className="search">
              <Form.Control
                onChange={(event) => setname(event.target.value)}
                type="text"
                placeholder="User name"
                className="input-search"
              />
              <span style={{ color: "red", marginLeft: 5 }}>{nameError}</span>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridEmail" className="search">
              <Form.Control
                onChange={(event) => setemail(event.target.value)}
                type="email"
                placeholder="Email"
                className="input-search"
              />
              <span style={{ color: "red", marginLeft: 5 }}>{emailError}</span>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              controlId="formGridPassword"
              className="search">
              <Form.Control
                onChange={(event) => setpassword(event.target.value)}
                type="password"
                placeholder="Password"
                className="input-search"
              />
              <span style={{ color: "red", marginLeft: 5 }}>
                {passwordError}
              </span>
            </Form.Group>

            <Form.Group
              as={Col}
              controlId="formGridConfirmation"
              className="search">
              <Form.Control
                onChange={(event) => setpasswordvalidation(event.target.value)}
                type="password"
                placeholder="Confirmation"
                className="input-search"
              />
              <span style={{ color: "red", marginLeft: 5 }}>
                {passwordvalidationError}
              </span>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <div className="search box-permission">
                <div className="input-search content-permission">
                  <i className="fa fa-key icon-form"></i>
                  Admin Permissions
                </div>
              </div>
            </Col>
          </Row>
          <Row className="list-permission">
            {Object.keys(permissions).map((key, index) => (
              <Col key={index} className="column-permission col-md-6">
                <Form.Check
                  inline
                  label={permissions[key].label}
                  name="permess"
                  type="checkbox"
                  onChange={() => {
                    const copy = { ...permissions };
                    copy[key].value = !copy[key].value;
                    setpermissions(copy);
                  }}
                  checked={permissions[key].value}
                  id={`inline-${index}-1`}
                />
              </Col>
            ))}
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridName" className="search">
              <Form.Label style={{ fontWeight: "bold", fontSize: 20 }}>
                Profile photo
              </Form.Label>
              <Form.Control
                onChange={(event) => {
                  setprofilephoto(event.target.files[0]);
                }}
                accept=".png, .jpg, .jpeg"
                data-classButton="btn btn-primary"
                data-input="false"
                data-classIcon="icon-plus"
                data-buttonText="Your label here."
                type="file"
                className="input-search"
              />
            </Form.Group>
          </Row>
          <div className="footer-modal">
            {!isloading ? (
              <>
                <Button
                  variant="default"
                  className="btn-return"
                  onClick={props.handleClose}>
                  Return
                </Button>
                <Button
                  variant="primary"
                  className="btn-add"
                  type="submit"
                  onClick={handleSubmit}>
                  Add
                </Button>
              </>
            ) : (
              <div>
                {[...Array(5).keys()].map((key) => (
                  <Spinner key={key} animation="grow" variant="info" />
                ))}
              </div>
            )}
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAddAdmin;
