import axios from "axios";

// const baseURL = "http://localhost:3030/api";
const baseURL = process.env.REACT_APP_BACKEND_LINK;
export const Axios = axios.create({
  baseURL: baseURL,
});
export const AxiosWithAuth = () => {
  const token = localStorage.getItem("token");
  Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  return Axios;
};
