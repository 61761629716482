import { toast } from "react-toastify";

export const transformDate = (date) => {
  const ndate = new Date(date);
  return ndate.toLocaleDateString("fr");
};
export const textToSlug = (text) => {
  return text.replace(/\s+/g, "-");
};
export const slugToText = (text) => {
  return text.replaceAll("-", " ");
};
export const handlePermissions = (e) => {
  if ([401, 403].indexOf(e.response.status) !== -1) {
    toast.error("You don't have permission to do this action");
    //remove token and user
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    //reload
    window.location.reload();
  }
};
export const youtubeLinkToEmbed = (link) => {
  const youtubeId = link.split("v=")[1];
  return `https://www.youtube.com/embed/${youtubeId}`;
};
export const LANG = {
  ar: "Arabic",
  fr: "Frensh",
  en: "English",
  es: "Spanish",
  filipino: "Filipino",
  sw: "Swahili",
  pt: "Portugueuse",
};
