import React, { useEffect } from "react";
import { useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ImgUser from "../../../assets/img/icons/user1.png";
import { api } from "../../../utils/api";
import HttpHandlerHoc from "../../HOC/httpHandlerHoc";

import "./Report.scss";
export const getDay = (date) => {
  const ndate = new Date(date);
  return ndate.toLocaleDateString("fr").split("/")[0];
};
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const cleanNumber = (number) => {
  return number.replace(/^0+/, "").replace(/, +$/, "");
};
const MonthlyTasks = () => {
  const [activity, setActivity] = useState([]);
  const { year, month, user } = useParams();
  useEffect(() => {
    (async () => {
      const { data } = await api.get(`activity`, {
        params: {
          year,
          month,
          user,
        },
      });
      setActivity(data.weeks);
    })();
  }, [year, month, user]);
  const dayAct = (data) =>
    data.reduce((acc, { name, value }) => {
      acc[name] = value;
      return acc;
    }, {});
  const titles = activity.length
    ? Object.keys(dayAct(activity[0].activities) || {})
    : null;
  console.log(activity);
  return (
    <>
      <div className="tasksUser">
        <div className="header-page header-report">
          <Container>
            <div className="content-header-page">
              <div className="info-user">
                <img src={ImgUser} alt="" />
                <div className="info">
                  <h4 className="name-user">{activity?.[0]?.owner?.name}</h4>
                  <h4 className="mx-4" style={{ color: "gray" }}>
                    {activity?.[0]?.owner?.degree}
                  </h4>
                </div>
              </div>
              {/* <Button variant="dafault" className="action-header">
                <i className="fa fa-trash icon-header"></i>
              </Button> */}
            </div>
          </Container>
        </div>
        <div className="reportsUser-items taksUser-item">
          <Container>
            <Table striped bordered hover responsive>
              <thead>
                <tr style={{ backgroundColor: "#e3b25f", color: "white" }}>
                  <th
                    style={{
                      fontSize: "0.7rem",
                    }}
                    className="text-center  "
                  >
                    <p
                      className="text-truncate text-capitalize"
                      style={{
                        margin: "0 auto",
                      }}
                    >
                      Month{":  " + MONTHS[month - 1]}
                    </p>
                  </th>
                  {titles?.map((title, index) => (
                    <th
                      key={title}
                      style={{
                        fontSize: "0.7rem",
                      }}
                      className="text-center  "
                    >
                      <p
                        className="text-truncate"
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        {title}
                      </p>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {activity.map((activity) => {
                  return (
                    <tr key={`week-${activity._id}`}>
                      {/* <td
                        style={{
                          fontSize: "0.7rem",
                        }}
                        className="text-center">
                        <p
                          className="text-truncate"
                          style={{
                            margin: "0 auto",
                          }}>
                          {getDay(activity.createdAt)}
                        </p>
                      </td> */}
                      {activity.activities.map((activity) => {
                        return (
                          <td
                            style={{
                              fontSize: "0.7rem",
                            }}
                            className="text-center"
                          >
                            <p
                              className="text-truncate"
                              style={{
                                margin: "0 auto",
                              }}
                            >
                              {activity.value}
                            </p>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                <tr style={{ backgroundColor: "#cd8100", color: "white" }}>
                  <td
                    style={{
                      fontSize: "0.7rem",
                    }}
                    className="text-center"
                  >
                    <p
                      className="text-truncate text-capitalize"
                      style={{
                        margin: "0 auto",
                        fontWeight: "bold",
                      }}
                    >
                      Total
                    </p>
                  </td>
                  {titles?.map((title) => {
                    const s = activity?.reduce((acc, { activities }) => {
                      const activity = activities.find(
                        (act) => act.name === title
                      );

                      const activityValue =
                        typeof activity?.value === "object"
                          ? activity?.value.length
                          : isNaN(parseInt(activity?.value))
                          ? activity?.value + ", "
                          : parseInt(activity?.value);
                      return acc + activityValue;
                    }, 0);
                    return (
                      <td
                        key={`total_${title}`}
                        style={{
                          fontSize: "0.8rem",
                        }}
                        className="text-center  "
                      >
                        <p
                          className="text-truncate"
                          style={{
                            margin: "0 auto",
                          }}
                        >
                          {cleanNumber(s.toString())}
                        </p>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </Table>
          </Container>
        </div>
      </div>
    </>
  );
};

export default MonthlyTasks;
