import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LANG, transformDate } from "../../../utils/index";
import ImgProfile from "../../../assets/img/profile.png";
import { api } from "../../../utils/api";
const ItemNewMuslim = ({ user, handleChanges, teachers }) => {
  const id = user._id || user.id;
  const handleDelete = async () => {
    await api.delete(`user/delete-new-muslim/${id}`);
    handleChanges((v) => !v);
  };
  const handleAccept = async () => {
    await api.put(`user/accept-new-muslim/${id}`);
    handleChanges((v) => !v);
  };
  return (
    <div className="column-dialogue col-lg-3 col-md-4 col-sm-6 col-xs-12">
      <Card>
        <Link to="/teachers/student/1">
          <Card.Img
            className="media-dialogue"
            variant="top"
            src={user.profile_photo || ImgProfile}
          />
        </Link>
        <Card.Body>
          <div className="content-body">
            <a>
              <Card.Title>{user.name}</Card.Title>
              <p className="info-country">
                Country:{"   "} <strong>{user.country}</strong>
              </p>
              <p className="info-country">
                Age :{"   "} <strong>{user.age}</strong>
              </p>
              <p className="info-country">
                Gender:{"   "} <strong>{user.gender}</strong>
              </p>
              <p className="info-country">
                Religion:{"   "} <strong>{user.religion}</strong>
              </p>
              <p className="info-country">
                Language :{"   "} <strong>{LANG[user.language]}</strong>
              </p>
              <p className="info-country">
                Daiya:{"   "} <strong>{user.referer}</strong>
              </p>
              <p className="info-country ">
                Facebook:{"   "}
                {/* button to open a new link */}
                <button
                  style={{
                    background: "#d2e8ff",
                    height: 22,
                    width: 80,
                    borderRadius: 10,
                    border: "none",
                    fontSize: 14,
                    color: "gray",
                  }}
                >
                  <a href={user.fb_link} target="_blank" rel="noreferrer">
                    Visit
                  </a>
                </button>
              </p>
              <p className="info-country">
                Teacher:{"   "}{" "}
                <select
                  style={{
                    background: "#d2e8ff",
                    width: "100%",
                    height: 22,
                    borderRadius: 10,
                    border: "none",
                    outline: "none",
                    fontSize: 12,
                    color: "gray",
                    paddingInline: 5,
                  }}
                >
                  <option value="0">Select Teacher</option>
                  {teachers?.map((teacher) => (
                    <option value={teacher.id}>{teacher.name}</option>
                  ))}
                </select>
              </p>
              <p className="info-country">
                Date: <strong>{transformDate(user.date_creation)}</strong>
              </p>
            </a>
          </div>
        </Card.Body>
        <Card.Footer>
          <Button variant="default" onClick={handleDelete}>
            <i className="fa fa-trash"></i>
          </Button>
          <Button variant="default" onClick={handleAccept}>
            <i className="fa fa-check"></i>
          </Button>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default ItemNewMuslim;
