import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { AxiosWithAuth } from "../../../context/axios";

export default function AddImage() {
  const [imageTitle, setImageTitle] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const addImage = async () => {
    if (!imageTitle) {
      toast.error("please add title for image");
    } else if (!imageFile) {
      toast.error("please add an image file");
    } else {
      let formData = new FormData();
      formData.append("title", imageTitle);
      formData.append("post_photo", imageFile);
      try {
        const res = await AxiosWithAuth().put("/channel/images/add", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (res.status == 200 || res.status == 201) {
          toast.success("added new image to the Channel");
          setImageTitle("");
          setImageFile(null);
        }
      } catch (error) {
        if (error.response.status == 400 || error.response.status == 422) {
          toast.error(error.response.data.message);
        } else {
          toast.error("something went wrong");
        }
      }
    }
  };
  return (
    <div className="file-input-container">
      <div className="input-label">Image title</div>
      <Form.Control
        type="text"
        placeholder="Image title"
        className="input-search input-title"
        name="image-title"
        onChange={(e) => setImageTitle(e.target.value)}
        value={imageTitle}
        required={true}
      />

      <div className="input-label">Upload image</div>
      <input
        type="file"
        name="file-image"
        className="input-file"
        accept="image/*"
        onChange={(e) => setImageFile(e.target.files[0])}
      />

      <Button
        type="button"
        className="btn btn-primary btn-add"
        onClick={addImage}
      >
        Add
      </Button>
    </div>
  );
}
