import axios from "axios";
import { handlePermissions } from ".";
export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_LINK,
});
api.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem("token");
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
  },
  (err) => {
    console.log(err);
  }
);
api.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    console.log(err);
    if (err.response.status === 401 || err.response.status === 403) {
      handlePermissions(err);
    }
    return err;
  }
);
