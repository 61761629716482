import React from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import chatIcon from "../../../assets/img/home/green-chat.png";
import editIcon from "../../../assets/img/home/green-edit.png";
import trashIcon from "../../../assets/img/home/green-trash.png";
import { api } from "../../../utils/api";

const SuspicionItem = (props) => {
  const navigate = useNavigate();
  return (
    <div className="suspicion-item">
      <Container>
        <div className="content-suspicion-item">
          <div className="level">
            <img src={chatIcon} alt="chat" />
            <h4 className="question">{props.question.title}</h4>
          </div>
          <div className="action-susp-item">
            <button
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              onClick={() => {
                navigate("/addquestion-suspicions", { state: props.question });
              }}>
              <div className="delete">
                <img src={editIcon} alt="edit" />
                EDIT
              </div>
            </button>
            <button
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              onClick={() => {
                props.onDelete(props.question._id);
              }}>
              <div className="delete">
                <img src={trashIcon} alt="trash" />
                Delete
              </div>
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SuspicionItem;
